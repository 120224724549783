<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Configura galerias de imagens por tela</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ConfigImg.lgiId" label="Id" variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-if="ListaTelas!=''">
                                    <v-select v-model="ConfigImg.ctiId" label="Telas" :items="ListaTelas"
                                        item-title="ctiNome" item-value="ctiId" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ConfigImg.lgiNome" :rules="nameRules" :counter="200"
                                        label="Nome Galeria" variant="underlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-text-field v-model="ConfigImg.lgiOrdem" label="Ordem" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select v-model="ConfigImg.lgiVisivel" label="Visível" :items="Status"
                                        item-title="nome" item-value="lgiVisivel" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.lgiLargura" label="Largura px (width)" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.lgiAltura" label="Altura px (height) " type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.lgiQtd" label="Quantidade" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ConfigImg.lgiId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ConfigImgs)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-lgiVisivel="item">
                            <span>{{ item.lgiVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-lgiOrdem="item">
                            <span>{{ parseInt(item.lgiOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ConfigListaImagensView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ConfigImg: {
            lgiId: 0,
            ctiId: '',
            lgiNome: '',
            lgiOrdem: '',
            lgiVisivel: '',
            lgiLargura: '',
            lgiAltura: '',
            lgiQtd: '',
        },
        ConfigImgs: [],
        ListaTelas:[],
        selectedVisivel: null,
        Status: [{ nome: "Sim", lgiVisivel: true }, { nome: "Não", lgiVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "lgiId", sortable: true, },
            { text: "Tela", value: "admCadastroTelasInst.ctiNome", sortable: true, },
            { text: "Nome", value: "lgiNome", sortable: true, },
            { text: "Ordem", value: "lgiOrdem", sortable: true, },
            { text: "Visivel", value: "lgiVisivel", sortable: true, },
            { text: "Largura", value: "lgiLargura", sortable: true, },
            { text: "Altura", value: "lgiAltura", sortable: true, },
            { text: "Qtde", value: "lgiQtd", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                lgiOrdem: parseInt(item.lgiOrdem),
            };
            });
        },
        Limpar() {
            this.ConfigImg.lgiId = 0;
            this.ConfigImg.ctiId = "";
            this.ConfigImg.lgiNome = "";
            this.ConfigImg.lgiOrdem = "";
            this.ConfigImg.lgiVisivel = "";
            this.ConfigImg.lgiLargura = "";
            this.ConfigImg.lgiAltura = "";
            this.ConfigImg.lgiQtd = "";
            this.selectedVisivel = "";
            this.Get();
        },
        Add() {
            if (this.ConfigImg.lgiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigImg.lgiOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigImg.lgiVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigImg.ctiId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigImg.lgiAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigImg.lgiLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigImg.lgiQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigImg.lgiOrdem = parseInt(this.ConfigImg.lgiOrdem)
                this.ConfigImg.lgiAltura = parseInt(this.ConfigImg.lgiAltura)
                this.ConfigImg.lgiLargura = parseInt(this.ConfigImg.lgiLargura)
                this.ConfigImg.lgiQtd = parseInt(this.ConfigImg.lgiQtd)
                this.ConfigImg.AdmCadastroTelasInst = { ctiId: this.ConfigImg.ctiId};
                axios.post(`/Process/InsListaGaleriaInstImagens/Add`, this.ConfigImg)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.ConfigImg.lgiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigImg.lgiOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigImg.lgiVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigImg.ctiId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigImg.lgiAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigImg.lgiLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigImg.lgiQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigImg.lgiOrdem = parseInt(this.ConfigImg.lgiOrdem)
                this.ConfigImg.lgiAltura = parseInt(this.ConfigImg.lgiAltura)
                this.ConfigImg.lgiLargura = parseInt(this.ConfigImg.lgiLargura)
                this.ConfigImg.lgiQtd = parseInt(this.ConfigImg.lgiQtd)
                this.ConfigImg.AdmCadastroTelasInst = { ctiId: this.ConfigImg.ctiId};
                axios.put(`/Process/InsListaGaleriaInstImagens/Update`, this.ConfigImg)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.ConfigImg = item;
            this.ConfigImg.ctiId = item.admCadastroTelasInst.ctiId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.lgiId;
                axios.delete(`/Process/InsListaGaleriaInstImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
                axios.get(`/Process/InsListaGaleriaInstImagens/GetAll`)
                .then(response => {
                    this.ConfigImgs = response.data;
                });
              
        }
    },
    created() {
        this.Get();

        axios.get(`Process/AdmCadastroTelasInst/GetTelasListaImage`)
                .then(response => {
                    this.ListaTelas = response.data;
                });
    },
}
</script>

<style></style>
