<template>
  <div style="background: #ecf0f4;color: #031e23;">
    <div class="login-header box-shadow">
      <div class="container-fluid d-flex justify-content-between align-items-center">
        <div class="brand-logo">
          <router-link to="/">
            <img src="/images/avance-logo-transparente.png" alt="" style="width:257px;height:auto;">
          </router-link>
        </div>
      </div>
    </div>
    <div class="login-wrap d-flex align-items-center flex-wrap justify-content-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-5">
            <div class="login-box bg-white box-shadow border-radius-10">
              <div class="login-title">
                <h2 class="text-center" style="color:#000000;">Atualizar Senha</h2>
              </div>
              <form>
                <div class="input-group custom">
                  <input v-model="senhaNova1" type="password" class="form-control form-control-lg"
                    placeholder="Digite a nova senha" @keyup.enter.prevent="validaUser()">
                  <div class="input-group-append custom">
                    <span class="input-group-text"><i class="dw dw-padlock1"></i></span>
                  </div>
                </div>
                <div class="input-group custom">
                  <input v-model="senhaNova2" type="password" class="form-control form-control-lg"
                    placeholder="Repita a nova senha" @keyup.enter.prevent="validaUser()">
                  <div class="input-group-append custom">
                    <span class="input-group-text"><i class="dw dw-padlock1"></i></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="input-group mb-0">
                      <a :loading="isLoading" class="btn btn-primary btn-lg btn-block" href="#"
                        @click="AtualizaSenha()">Atualizar Senha</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 col-lg-7">
            <img src="/images/login-page-img.png" alt="" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { SHA256 } from 'crypto-js';
import { useRoute } from 'vue-router';

export default {
  name: 'ResetSenhaView',
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const key = route.params.key;
    return {
      id, key
    };
  },
  components: {
    moment
  },
  data: () => ({
    id: '',
    senhaNova1: '',
    senhaNova2: '',
    User: [],
  }),
  methods: {
    AtualizaSenha() {
      if (this.senhaNova1 != this.senhaNova2) {
        this.$mensagemErro('Senha não são iguais.');
      } else {
        let _dados = {
          cusId: parseInt(this.id),
          cusNome: this.User.cusNome,
          cusEmail: this.User.cusEmail,
          cusTelefone: this.User.cusTelefone,
          cusWhatsapp: this.User.cusWhatsapp,
          cusUrlImagem: this.User.cusUrlImagem,
          cusSenha: this.senhaNova1,
          admTipoUsuario: {
            tusId: this.User.admTipoUsuario.tusId,
          },
          cusAtivo: this.User.cusAtivo
        }
        axios.put(`/Process/UsrCadastroUsuarios/Update`, _dados)
          .then(response => {
            if (response.status == 200) {
              let _dadosEmail = {
                nomeDe: 'Sistema Sgcad',
                nomePara: this.User.cusNome,
                assunto: 'Senha Atualizada',
                destinatario: this.User.cusEmail,
                emailResposta: 'contato@formulariodosite.com.br',
                textoEmail: 'Sua Senha foi atualizada com sucesso! <br><br> Att,<br>Equipe',
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
              }
              axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                .then(response => {
                  if (response.data > 1) {
                    this.$mensagemSucesso("Senha atualizada, faça login.")
                    this.$router.push('/Login')
                  }
                });
            }
          });
      }

    }
  },
  created() {
    if (this.id) {
      const param1 = this.id;
      const param2 = this.key;

      axios.get(`/Process/UsrCadastroUsuarios/GetById/${param1}`)
        .then(response => {
          this.User = response.data;
          const originalHash = param2;

          const valueToCompare = this.User.cusEmail;
          const compareHash = SHA256(valueToCompare).toString();
          if (originalHash != compareHash) {
            this.$mensagemErro("Não é possível resetar senha, contato administrador.")
            this.$router.push('/Login')
          }
        });
    }
  }
}
</script>

<style>
.v-card-actions {
  align-items: center;
  display: flex;
  flex: none;
  min-height: 52px;
  padding: 0.5rem;
  padding-right: 20px !important;
}
</style>
