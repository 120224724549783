<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <div v-if="Produtos.proId != 0">
                           <v-tab :value="tabmenu.pliNome" v-for="tabmenu in ListaProdAvImgs" :key="tabmenu.pliId" class="tabinfo">{{tabmenu.pliNome}}</v-tab>
                        </div> 
                        <div v-if="MostraSegmento">
                            <v-tab value="segmentos" class="tabinfo">Segmentos</v-tab>
                        </div>
                        <v-tab v-for="tabmenu in ListaProdGrids" :key="tabmenu.plgId" :value="tabmenu.plgNome" class="tabinfo">{{tabmenu.plgNome}}</v-tab>
                        <v-tab v-for="tabmenu in ListaProdArqs" :key="tabmenu.plaId" :value="tabmenu.plaNome" class="tabinfo">{{tabmenu.plaNome}}</v-tab>
                        <div v-if="MostraAvulsos">
                            <v-tab value="Avulsos" class="tabinfo">Produtos Avulsos</v-tab>
                        </div>
                        <div v-if="MostraPrecos">
                            <v-tab value="Precos" class="tabinfo">Preços e Estoque</v-tab>
                        </div>
                    </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Produtos.proId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Produtos.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Produtos.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Produtos.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(Produtos.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="9">
                                            <v-text-field v-model="Produtos.proNomeProduto"
                                                label="Nome Produto" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proReferencia"
                                                label="Referência" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Produtos.proVisivel" label="Visível" :items="StatusVisivel"
                                                item-title="nome" item-value="proVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proOrdem" label="Ordem" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Produtos.proDestaque" label="Destaque" :items="StatusDestaque"
                                                item-title="nome" item-value="proDestaque" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proTempoProducao"
                                                label="Tempo Produção (min)" variant="underlined" required type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display:none">
                                            <v-text-field v-model="Produtos.proValorDe" type="number"
                                            @change="Produtos.proValorDe != null || Produtos.proValorDe != '' ? (Produtos.proValorDe = parseFloat(Produtos.proValorDe,10)) : (Produtos.proValorDe = 0.0)"
                                                label="Valor De" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display:none">
                                            <v-text-field v-model="Produtos.proValorPor" type="number"
                                            @change="Produtos.proValorPor != null || Produtos.proValorPor != '' ? (Produtos.proValorPor = parseFloat(Produtos.proValorPor,10)) : (Produtos.proValorPor = 0.0)"
                                                label="Valor Por" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <small style="padding-bottom:5px;"><strong>Descrição</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Produtos.proTexto1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <small style="padding-bottom:5px;"><strong>Palavras-Chaves</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Produtos.proTexto2" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display:none">
                                            <small style="padding-bottom:5px;"><strong>Descrição</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Produtos.proTexto3" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display:none">
                                            <small style="padding-bottom:5px;"><strong>Descrição</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="Produtos.proTexto4" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Produtos.proEtiqueta"
                                                label="Etiqueta" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Produtos.proEtiquetaCorFonte" type="color"
                                                label="Cor Fonte" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Produtos.proEtiquetaCorFundo" type="color"
                                                label="Cor Fundo" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proPeso" type="number"
                                            @change="Produtos.proPeso != null || Produtos.proPeso != '' ? (Produtos.proPeso = parseFloat(Produtos.proPeso,10)) : (Produtos.proPeso = 0.0)"
                                                label="Peso (gramas)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proAltura" type="number"
                                            @change="Produtos.proAltura != null || Produtos.proAltura != '' ? (Produtos.proAltura = parseFloat(Produtos.proAltura,10)) : (Produtos.proAltura = 0.0)"
                                                label="Altura (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proLargura" type="number"
                                            @change="Produtos.proLargura != null || Produtos.proLargura != '' ? (Produtos.proLargura = parseFloat(Produtos.proLargura,10)) : (Produtos.proLargura = 0.0)"
                                                label="Largura (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Produtos.proProfundidade" type="number"
                                            @change="Produtos.proProfundidade != null || Produtos.proProfundidade != '' ? (Produtos.proProfundidade = parseFloat(Produtos.proProfundidade,10)) : (Produtos.proProfundidade = 0.0)"
                                                label="Profundidade (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra1"
                                                label="Campo Extra 1" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra2"
                                                label="Campo Extra 2" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra3"
                                                label="Campo Extra 3" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra4"
                                                label="Campo Extra 4" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra5"
                                                label="Campo Extra 5" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra6"
                                                label="Campo Extra 6" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra7"
                                                label="Campo Extra 7" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra8"
                                                label="Campo Extra 8" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra9"
                                                label="Campo Extra 9" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="Produtos.proCampoExtra10"
                                                label="Campo Extra 10" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <!--Imagens-->
                        <v-window-item v-for="tabmenu in ListaProdAvImgs" :key="tabmenu.pliId" :value="tabmenu.pliNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de até {{tabmenu.pliQtd}} {{tabmenu.pliQtd > 1 ? 'imagens com dimensões' : 'imagem com dimensão'}} : Largura: {{tabmenu.pliLargura}}px - Altura: {{tabmenu.pliAltura}}px</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFile" multiple label="Imagens" variant="underlined"
                                            prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddImagens(tabmenu.pliId)">
                                            Carregar Imagens
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row v-for="imgs in ProdutosImgs.filter(x=> x.proListaGaleriaImagens.pliId == tabmenu.pliId)" :key="imgs.pimId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="imgs.pimId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" md="12">
                                                    <v-img :src="$imgURL + imgs.pimUrlImagem" variant="underlined"></v-img>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.pimNome" label="Nome" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.pimOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-select v-model="imgs.pimVisivel" label="Visível" :items="StatusProdAvImgVisivel"
                                                        item-title="nome" item-value="pimVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.pimAlt" label="Alt" variant="underlined" style="display:none"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4" style="display:none">
                                                    <small style="padding-bottom:5px;"><strong>Texto</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="imgs.pimResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn icon @click="SaveImages(imgs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteImages(imgs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                         <!--Segmentos-->
                          <v-window-item value="segmentos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Seleção de Segmentos</div>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="10">
                                            <v-select v-model="ProdutosSegs.psgId" label="Segmentos" :items="ListaHierarquiaSeg"
                                                item-title="psgNomeSegmento" item-value="psgId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                        <button type="button" class="btn btn-success" @click="AddSegmentos(Produtos.proId)">
                                            Incluir
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-table>
                                            <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Hierarquia de Segmentos
                                                </th>
                                                <th class="text-center">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in ListaSegmentos" :key="item.pssId">
                                                <td>{{ item.Hierarquia }}</td>
                                                <td style="text-align:center;">  
                                                    <v-btn icon @click.prevent="DeleteSegmentos(item)" variant="text">
                                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                                        <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-table>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <!--Grids-->
                        <v-window-item v-for="tabmenu in ListaProdGrids" :key="tabmenu.plgId" :value="tabmenu.plgNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Lista Itens Grid</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="ProdutosGrids.pgsId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="ProdutosGrids.pgsNome" label="Título" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" style="display:none;">
                                        <v-text-field v-model="ProdutosGrids.pgsCampoExtra1" label="Campo Extra 1" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" style="display:none;">
                                        <v-text-field v-model="ProdutosGrids.pgsCampoExtra2" label="Campo Extra 2" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" style="display:none;">
                                        <v-text-field v-model="ProdutosGrids.pgsCampoExtra3" label="Campo Extra 3" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" style="display:none;">
                                        <v-text-field v-model="ProdutosGrids.pgsCampoExtra4" label="Campo Extra 4" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field v-model="ProdutosGrids.pgsOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select v-model="ProdutosGrids.pgsVisivel" label="Visível" :items="StatusGridVisivel"
                                            item-title="nome" item-value="pgsVisivel" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="4" style="display:none;">
                                         <v-file-input v-model="selectedFileGrid" label="Imagem" variant="underlined"
                                            prepend-icon="mdi-file-upload-outline" accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <button type="button" class="btn btn-success" @click="AddGrids(tabmenu.plgId)">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                         Título
                                                    </th>
                                                    <th class="text-left" style="display:none;">
                                                       Campo Extra 1
                                                    </th>
                                                    <th class="text-left" style="display:none;">
                                                       Campo Extra 2
                                                    </th>
                                                    <th class="text-left" style="display:none;">
                                                       Campo Extra 3
                                                    </th>
                                                    <th class="text-left" style="display:none;">
                                                       Campo Extra 4
                                                    </th>
                                                    <th class="text-left">
                                                       Ordem
                                                    </th>
                                                    <th class="text-left">
                                                       Visível
                                                    </th>
                                                    <th class="text-center" style="display:none;">
                                                       Imagem
                                                    </th>
                                                    <th class="text-center">
                                                       Actions
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in GridsItens.filter(x=> x.proListaGaleriaGrids.plgId == tabmenu.plgId)" :key="item.pgsId">
                                                    <td>{{ item.pgsNome }}</td>
                                                    <td style="display:none;">{{ item.pgsCampoExtra1 }}</td>
                                                    <td style="display:none;">{{ item.pgsCampoExtra2 }}</td>
                                                    <td style="display:none;">{{ item.pgsCampoExtra3 }}</td>
                                                    <td style="display:none;">{{ item.pgsCampoExtra4 }}</td>
                                                    <td>{{ item.pgsOrdem }}</td>
                                                    <td>{{ item.pgsVisivel?'Sim':'Não' }}</td>
                                                    <td style="text-align:center;display:none;">
                                                        <a v-if="item.pgsUrlImagem!=''" :href="$imgURL + item.pgsUrlImagem" target="_blank">
                                                            <v-icon>mdi-file-eye-outline</v-icon>
                                                       </a>
                                                   </td>
                                                    <td style="text-align:center;">  
                                                        <v-btn icon @click.prevent="DeleteGrids(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                        <!--Arquivos-->
                        <v-window-item v-for="tabmenu in ListaProdArqs" :key="tabmenu.plaId" :value="tabmenu.plaNome" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de até {{tabmenu.plaQtd}} {{tabmenu.plaQtd > 1 ? 'arquivos com até' : 'arquivo com até'}} : Peso: {{tabmenu.plaPesoMb}}mb</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFileArq" multiple label="Arquivos" variant="underlined"
                                            prepend-icon="mdi-file-upload-outline" accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddArquivos(tabmenu.plaId)">
                                            Carregar Arquivos
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row v-for="arqs in ProdutosArqs.filter(x=> x.proListaGaleriaArquivos.plaId == tabmenu.plaId)" :key="arqs.parId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="arqs.parId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" md="12" style="text-align:center;">
                                                    <a :href="$imgURL + arqs.parUrlArquivo" target="_blank">
                                                        <v-icon style="font-size:60px;">mdi-file-eye-outline</v-icon><br>
                                                        <span style="font-size:8px;width: 100%;">{{ arqs.parUrlArquivo }}</span>
                                                    </a>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8">
                                                <v-row>
                                                <v-col cols="12"  md="6">
                                                    <v-text-field v-model="arqs.parNome" label="Nome" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="arqs.parOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-select v-model="arqs.parVisivel" label="Visível" :items="StatusArqVisivel"
                                                        item-title="nome" item-value="parVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <small style="padding-bottom:5px;"><strong>Texto</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="arqs.parResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn icon @click="SaveArquivos(arqs)" style="margin-right: 10px;" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteArquivos(arqs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                         <!--Produtos Avulsos-->
                         <v-window-item value="Avulsos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Lista Produtos Avulsos</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="10">
                                            <v-select v-model="ProdutosAvulsos.praId" label="Produtos Avulsos" :items="ListaAvulsosGeral"
                                                item-title="praNomeProduto" item-value="praId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                        <button type="button" class="btn btn-success" @click="AddAvulsos(Produtos.proId)">
                                            Incluir
                                        </button>
                                 </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                            <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Produto Avulso
                                                </th>
                                                <th class="text-center">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in ListaAvulsos" :key="item.papId">
                                                <td>{{ ListaAvulsosGeral.filter(x=> x.praId == item.proProdutosAvulso.praId)[0].praNomeProduto }}</td>
                                                <td style="text-align:center;">  
                                                    <v-btn icon @click.prevent="DeleteAvulsos(item)" variant="text">
                                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                                        <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                          <!--Precos -->
                          <v-window-item value="Precos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Lista Preços / Estoque</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                        <v-col cols="12" md="6" v-if="ListaTabelaPreco!='' && motraProdutosPrecos">
                                            <v-autocomplete v-model="ProdutosPreco.ptcId" label="Tabela de Preço" :items="ListaTabelaPreco" @update:modelValue="CarregaListaProdutosPrecos(ProdutosPreco.ptcId)"
                                                item-title="ptcIdentificador" item-value="ptcId" variant="underlined" searchable>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                <v-form v-if="ProdutosPreco.ptcId!=''">
                                    <v-container>
                                        <v-row>      
                                            <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ProdutosPreco.ppcId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>                                  
                                            <v-col cols="12" md="3" sm="6" xs="6">
                                                <v-text-field v-model="ProdutosPreco.ppcValorDe" type="number"
                                                            @change="ProdutosPreco.ppcValorDe != null || ProdutosPreco.ppcValorDe != '' ? (ProdutosPreco.ppcValorDe = parseFloat(ProdutosPreco.ppcValorDe,10)) : (ProdutosPreco.ppcValorDe = 0.0)"
                                                         label="Valor De" variant="underlined" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3" sm="6" xs="6">
                                                <v-text-field v-model="ProdutosPreco.ppcValorPor" type="number"
                                                            @change="ProdutosPreco.ppcValorPor != null || ProdutosPreco.ppcValorPor != '' ? (ProdutosPreco.ppcValorPor = parseFloat(ProdutosPreco.ppcValorPor,10)) : (ProdutosPreco.ppcValorPor = 0.0)"
                                                         label="Valor Por" variant="underlined" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2" sm="6" xs="6">
                                                <v-select v-model="ProdutosPreco.ppcDisponivel" label="Disponível" :items="StatusProdAtivo"
                                                            item-title="nome" item-value="ppcDisponivel" variant="underlined">
                                                        </v-select>
                                            </v-col>
                                            <v-col cols="12" md="2" sm="6" xs="6">
                                                <v-text-field v-model="ProdutosPreco.ppcEstoque" label="Estoque" type="number"
                                                          variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcPadrao?'Sim':'Não' }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcOrdem }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcCampoExtra1 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcCampoExtra2 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcCampoExtra3 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcCampoExtra4 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcCampoExtra5 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcTexto1 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ ProdutosPreco.ppcTexto2 }}
                                            </v-col>
                                            <v-col cols="12" md="1" sm="6" xs="6" style="display:inline-flex;">
                                                <v-btn v-if="ProdutosPreco.ppcId == 0" icon @click.prevent="AddProdutoPreco(ProdutosPreco)" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                        <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>
                                                <v-btn v-else icon @click.prevent="SalvaProdutosPrecos(ProdutosPreco)" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                        <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Produtos.proId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListProdutos)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-proVisivel="item">
                            <span>{{ item.proVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-proDestaque="item">
                            <span>{{ item.proDestaque ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-proValorDe="item">
                            {{ item.proValorDe !== null ? formatarValor(item.proValorDe) : 0 }}
                        </template>
                        <template #item-proValorPor="item">
                            {{ item.proValorPor !== null ? formatarValor(item.proValorPor) : 0 }}
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ProProdutosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Produtos',
        InfoTela:'Lista de produtos',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 14, cdtNome: "Produtos" }],
        //dados
        Produtos: {
            proId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            proNomeProduto: '',
            proReferencia: '',
            proTempoProducao: '',
            proValorDe: '',
            proValorPor: '',
            proVisivel: '',
            proOrdem: '',
            proDestaque: '',
            proTexto1: '',
            proEtiqueta: '',
            proEtiquetaCorFonte: '',
            proEtiquetaCorFundo: '',
            proPeso: '',
            proAltura: '',
            proLargura: '',
            proProfundidade: '',
            proCampoExtra1: '',
            proCampoExtra2: '',
            proCampoExtra3: '',
            proCampoExtra4: '',
        },
        ListProdutos: [],
        StatusVisivel: [{ nome: "Sim", proVisivel: true }, { nome: "Não", proVisivel: false }],
        StatusDestaque: [{ nome: "Sim", proDestaque: true }, { nome: "Não", proDestaque: false }],
        //dados
        //imagens
        ProdutosImgs:[],
        ListaProdAvImgs:[],
        ImagemProdutos:{},
        selectedFile: null,
        StatusProdAvImgVisivel: [{ nome: "Sim", pimVisivel: true }, { nome: "Não", pimVisivel: false }],
        //imagens
         //Grids
         ProdutosGrids:{
            pgsId:0,
            plgId:'',
            pgsUrlImagem:'',
            pgsOrdem:'',
            pgsNome:'',
            pgsResumo:'',
            pgsCampoExtra1:'',
            pgsCampoExtra2:'',
            pgsCampoExtra3:'',
            pgsCampoExtra4:'',
            pgsVisivel:'',
            proId:''
        },
        GridsItens:[],
        ListaProdGrids:[],
        StatusGridVisivel: [{ nome: "Sim", pgsVisivel: true }, { nome: "Não", pgsVisivel: false }],
        selectedFileGrid: null,
        //Grids
        //arquivos
        ProdutosArqs:[],
        ListaProdArqs:[],
        ArquivoProd:{},
        selectedFileArq: null,
        StatusArqVisivel: [{ nome: "Sim", parVisivel: true }, { nome: "Não", parVisivel: false }],
        //arquivos
        //Segmentos
        ProdutosSegs:{
            pssId:0,
            proId:0,
            psgId:'',
            pssOrdem:0,
            Hierarquia:'',
        },
        ListaHierarquiaSeg:[],
        ListaSegmentos:[],
        ListSegmentosProdGeral:[],
        MostraSegmento:false,
        //Segmentos
        //Produtos Avulsos
        ProdutosAvulsos:{
            papId:0,
            proId:0,
            praId:'',
            papOrdem:0
        },
        ListaAvulsos:[],
        ListaAvulsosGeral:[],
        MostraAvulsos:false,
        //Produtos Avulsos
        //Tabela Preco
        ProdutosPreco:{
            ppcId: 0,
            ptcId: '',
            proId:'',
            ppcCampoExtra1:'',
            ppcCampoExtra2:'',
            ppcCampoExtra3:'',
            ppcCampoExtra4:'',
            ppcCampoExtra5:'',
            ppcTexto1:'',
            ppcTexto2:'',
            ppcValorDe:'',
            ppcValorPor:'',
            ppcDisponivel:'',
            ppcPadrao:'',
            ppcOrdem:'',
            ppcEstoque:''
         },
         StatusProdAtivo:  [{ nome: "Sim", ppcDisponivel: true }, { nome: "Não", ppcDisponivel: false }],
         ListaTabelaPreco:[],
         MostraPrecos:false,
         ListaProdutosPrecos:[],
         motraProdutosPrecos:true,
         motraProdutosPrecosPorTabela:false,
        //Tabela Preco
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListProdutos = this.ListProdutosGeral.filter(x=> x.idiId == this.Produtos.idiId);
        },
        Limpar() {
            this.Produtos.proId=0;
            this.Produtos.grtId=0;
            this.Produtos.cdtId=0;
            this.Produtos.proNomeProduto="";
            this.Produtos.proReferencia="";
            this.Produtos.proTempoProducao="";
            this.Produtos.proValorDe="";
            this.Produtos.proValorPor="";
            this.Produtos.proVisivel="";
            this.Produtos.proOrdem="";
            this.Produtos.proDestaque="";
            this.Produtos.proTexto1="";
            this.Produtos.proTexto2="";
            this.Produtos.proTexto3="";
            this.Produtos.proTexto4="";
            this.Produtos.proEtiqueta="";
            this.Produtos.proEtiquetaCorFonte="";
            this.Produtos.proEtiquetaCorFundo="";
            this.Produtos.proPeso="";
            this.Produtos.proAltura="";
            this.Produtos.proLargura="";
            this.Produtos.proProfundidade="";
            this.Produtos.proCampoExtra1="";
            this.Produtos.proCampoExtra2="";
            this.Produtos.proCampoExtra3="";
            this.Produtos.proCampoExtra4="";
            this.Produtos.proCampoExtra5="";
            this.Produtos.proCampoExtra6="";
            this.Produtos.proCampoExtra7="";
            this.Produtos.proCampoExtra8="";
            this.Produtos.proCampoExtra9="";
            this.Produtos.proCampoExtra10="";
            this.ListaProdAvImgs = [];
            this.ListaProdArqs = [],
            this.ListaProdGrids = [],
            this.MostraSegmento = false;
            this.MostraAvulsos = false;
            this.MostraPrecos = false;
            this.LimpaProdutosPreco();
            this.tab = 'dados';
            this.Get();
        },
        //imagens
        async AddImagens(value){           
            if (this.selectedFile != '' && this.selectedFile != null) {
                var qtdebase = this.ProdutosImgs.filter(x=> x.proListaGaleriaImagens.pliId == value).length;
                if ((this.selectedFile.length + qtdebase) <= this.ListaProdAvImgs.filter(w=> w.pliId == value)[0].pliQtd){
                    let selected2Promises = this.selectedFile.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ImagemProdutos.pimUrlImagem = uploadSuccess.data.nameFile;
                                this.ImagemProdutos.pimVisivel = true;
                                this.ImagemProdutos.proListaGaleriaImagens = { PliId: value };
                                this.ImagemProdutos.proId = this.Produtos.proId
                                this.ImagemProdutos.pimResumo = "";
                                return axios.post(`/Process/ProProdutosImagens/Add`, this.ImagemProdutos);
                            } else {
                                this.$mensagemErro("Não foi possível carregar a imagem.");
                                return null;
                            }
                    });

                    Promise.all(selected2Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaImagens();
                            this.selectedFile = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Alguma imagem pode ter dado erro na carga." + error);
                        });
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de imagens excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione uma imagem.")
                }
        },
        SaveImages(value){
            var _imginfo = value;
            if (_imginfo.pimOrdem === "" || _imginfo.pimOrdem === null) {
                _imginfo.pimOrdem = 0;
            }
            else {
                _imginfo.pimOrdem = parseInt(_imginfo.pimOrdem);
            }
            
            if (_imginfo.pimResumo === "" || _imginfo.pimResumo === null){
                _imginfo.pimResumo = "";
            }
            axios.put(`/Process/ProProdutosImagens/Update`, _imginfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaImagens();
                        }
                    });
        },
        DeleteImages(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.pimId;
                axios.delete(`/Process/ProProdutosImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaImagens();
                    }
                });
            }
            });
        },
        CarregaImagens(){
            axios.get(`/Process/ProProdutosImagens/GetAllByCtiId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ProdutosImgs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.pimOrdem) || 0;
                                                                                const valorB = parseInt(b.pimOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                    });
        },
         //imagens
         //grids
         async AddGrids(value){
            if (this.selectedFileGrid != '' && this.selectedFileGrid != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFileGrid[0]);
                    if (uploadSuccess.status == 200) {
                            this.ProdutosGrids.pgsUrlImagem = uploadSuccess.data.nameFile;
                            this.ProdutosGrids.proListaGaleriaGrids = { PlgId: value };
                            this.ProdutosGrids.proId = this.Produtos.proId
                            if (this.ProdutosGrids.pgsOrdem === "" || this.ProdutosGrids.pgsOrdem === null) {
                                this.ProdutosGrids.pgsOrdem = 0;
                            }
                            else {
                                this.ProdutosGrids.pgsOrdem = parseInt(this.ProdutosGrids.pgsOrdem);
                            }
                            if (this.ProdutosGrids.pgsVisivel === "" || this.ProdutosGrids.pgsVisivel === null) {
                                this.ProdutosGrids.pgsVisivel = true;
                            }
                        axios.post(`/Process/ProProdutosGrids/Add`, this.ProdutosGrids)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaGrids();
                                this.LimpaGridInput();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.ProdutosGrids.pgsUrlImagem = "";
                    this.ProdutosGrids.proListaGaleriaGrids = { PlgId: value };
                    this.ProdutosGrids.proId = this.Produtos.proId
                    if (this.ProdutosGrids.pgsOrdem === "" || this.ProdutosGrids.pgsOrdem === null) {
                        this.ProdutosGrids.pgsOrdem = 0;
                    }
                    else {
                        this.ProdutosGrids.pgsOrdem = parseInt(this.ProdutosGrids.pgsOrdem);
                    }
                    if (this.ProdutosGrids.pgsVisivel === "" || this.ProdutosGrids.pgsVisivel === null) {
                        this.ProdutosGrids.pgsVisivel = true;
                    }
                    axios.post(`/Process/ProProdutosGrids/Add`, this.ProdutosGrids)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaGrids();
                                this.LimpaGridInput();
                            }
                        });
                }
        },
        DeleteGrids(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.pgsId;
                axios.delete(`/Process/ProProdutosGrids/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaGrids();
                    }
                });
            }
            });
        },
        CarregaGrids(){
            axios.get(`/Process/ProProdutosGrids/GetAllByCtiId/${this.Produtos.proId}`)
                    .then(response => {
                        this.GridsItens = response.data.sort((a, b) => {
                                                                        const valorA = parseInt(a.pgsOrdem) || 0;
                                                                        const valorB = parseInt(b.pgsOrdem) || 0;
                                                                        return valorA - valorB;
                                                                    });
                    });
        },
        LimpaGridInput(){
            this.ProdutosGrids.pgsId=0;
            this.ProdutosGrids.pgsNome= '';
            this.ProdutosGrids.pgsResumo='';
            this.ProdutosGrids.pgsCampoExtra1= '';
            this.ProdutosGrids.pgsCampoExtra2= '';
            this.ProdutosGrids.pgsCampoExtra3= '';
            this.ProdutosGrids.pgsCampoExtra4= '';
            this.ProdutosGrids.pgsOrdem= '';
            this.ProdutosGrids.pgsVisivel= '';
            this.ProdutosGrids.pgsUrlImagem= '';
            this.selectedFileGrid = null;
        },
        //grids
        //arquivos
        async AddArquivos(value){           
            if (this.selectedFileArq != '' && this.selectedFileArq != null) {
                var qtdebase = this.ProdutosArqs.filter(x=> x.proListaGaleriaArquivos.plaId == value).length;
                if ((this.selectedFileArq.length + qtdebase) <= this.ListaProdArqs.filter(w=> w.plaId == value)[0].plaQtd){
                    let selected3Promises = this.selectedFileArq.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ArquivoProd.parUrlArquivo = uploadSuccess.data.nameFile;
                                this.ArquivoProd.parVisivel = true;
                                this.ArquivoProd.proListaGaleriaArquivos = { PlaId: value };
                                this.ArquivoProd.proId = this.Produtos.proId;
                                this.ArquivoProd.parResumo = " ";
                                return axios.post(`/Process/ProProdutosArquivos/Add`, this.ArquivoProd);
                            } else {
                                this.$mensagemErro("Não foi possível carregar arquivo.");
                                return null;
                            }
                    });

                    Promise.all(selected3Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaArquivos();
                            this.selectedFileArq = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Algum arquivo pode ter dado erro na carga." + error);
                        });
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de arquivos excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione um arquivo.")
                }
        },
        SaveArquivos(value){
            var _arqinfo = value;
            if (_arqinfo.parOrdem === "" || _arqinfo.parOrdem === null) {
                _arqinfo.parOrdem = 0;
            }
            else {
                _arqinfo.parOrdem = parseInt(_arqinfo.parOrdem);
            }
           axios.put(`/Process/ProProdutosArquivos/Update`, _arqinfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaArquivos();
                        }
                    });
        },
        DeleteArquivos(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.parId;
                axios.delete(`/Process/ProProdutosArquivos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaArquivos();
                    }
                });
            }
            });
        },
        CarregaArquivos(){
            axios.get(`/Process/ProProdutosArquivos/GetAllByCtiId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ProdutosArqs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.parOrdem) || 0;
                                                                                const valorB = parseInt(b.parOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });
                    });
        },
        //arquivos
        //produtos
        Add() {
               if (this.Produtos.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Produtos.proNomeProduto == '') {
                    this.$mensagemAviso("Campo Nome Produto Obrigatório.")
                }
                else if (this.Produtos.proVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else if (this.Produtos.proOrdem === '') {
                    this.$mensagemAviso("Campo Ordem Obrigatório.")
                }
                else {
                    if (this.Produtos.proVisivel === "" || this.Produtos.proVisivel === null) {
                        this.Produtos.proVisivel = false;
                    }
                    if (this.Produtos.proDestaque === "" || this.Produtos.proDestaque === null) {
                        this.Produtos.proDestaque = false;
                    }
                    if (this.Produtos.proOrdem === "" || this.Produtos.proOrdem === null) {
                        this.Produtos.proOrdem = 0;
                    }
                    else {
                        this.Produtos.proOrdem = parseInt(this.Produtos.proOrdem);
                    }
                    if (this.Produtos.proTempoProducao === "" || this.Produtos.proTempoProducao === null) {
                        this.Produtos.proTempoProducao = 0;
                    }
                    else {
                        this.Produtos.proTempoProducao = parseInt(this.Produtos.proTempoProducao);
                    }
                    if (this.Produtos.proPeso === "" || this.Produtos.proPeso === null) {
                        this.Produtos.proPeso = 0;
                    }
                    else {
                        this.Produtos.proPeso = parseFloat(this.Produtos.proPeso);
                    }
                    if (this.Produtos.proAltura === "" || this.Produtos.proAltura === null) {
                        this.Produtos.proAltura = 0;
                    }
                    else {
                        this.Produtos.proAltura = parseFloat(this.Produtos.proAltura);
                    }
                    if (this.Produtos.proLargura === "" || this.Produtos.proLargura === null) {
                        this.Produtos.proLargura = 0;
                    }
                    else {
                        this.Produtos.proLargura = parseFloat(this.Produtos.proLargura);
                    }
                    if (this.Produtos.proProfundidade === "" || this.Produtos.proProfundidade === null) {
                        this.Produtos.proProfundidade = 0;
                    }
                    else {
                        this.Produtos.proProfundidade = parseFloat(this.Produtos.proProfundidade);
                    }
                    if (this.Produtos.proValorDe === "" || this.Produtos.proValorDe === null) {
                        this.Produtos.proValorDe = 0;
                    }
                    else {
                        this.Produtos.proValorDe = parseFloat(this.Produtos.proValorDe);
                    }
                    if (this.Produtos.proValorPor === "" || this.Produtos.proValorPor === null) {
                        this.Produtos.proValorPor = 0;
                    }
                    else {
                        this.Produtos.proValorPor = parseFloat(this.Produtos.proValorPor);
                    }

                    this.Produtos.admGrupoTelas = { GrtId: this.Produtos.grtId }
                    this.Produtos.admCadastroTelas = { CdtId: this.Produtos.cdtId }
                    this.Produtos.cadIdiomas = { IdiId: this.Produtos.idiId }
                 
                    axios.post(`/Process/ProProdutos/Add`, this.Produtos)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Produtos.proId = response.data;
                                    this.Get();
                                    this.EditGrid(this.Produtos);
                                    this.$mensagemAvisoSucesso("Registro Incluído. aba imagens liberada.")

                                   //this.$mensagemAvisoSucesso("Registro Incluído.")
                                   //this.Get();
                                   //this.Limpar();
                                  // this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.Produtos.idiId == '') {
                this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.Produtos.proNomeProduto == '') {
                this.$mensagemAviso("Campo Nome Produto Obrigatório.")
            }
            else if (this.Produtos.proVisivel === '') {
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else if (this.Produtos.proOrdem === '') {
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else {
                if (this.Produtos.proVisivel === "" || this.Produtos.proVisivel === null) {
                    this.Produtos.proVisivel = false;
                }
                if (this.Produtos.proDestaque === "" || this.Produtos.proDestaque === null) {
                    this.Produtos.proDestaque = false;
                }
                if (this.Produtos.proOrdem === "" || this.Produtos.proOrdem === null) {
                    this.Produtos.proOrdem = 0;
                }
                else {
                    this.Produtos.proOrdem = parseInt(this.Produtos.proOrdem);
                }
                if (this.Produtos.proTempoProducao === "" || this.Produtos.proTempoProducao === null) {
                        this.Produtos.proTempoProducao = 0;
                    }
                    else {
                        this.Produtos.proTempoProducao = parseInt(this.Produtos.proTempoProducao);
                    }
                    if (this.Produtos.proPeso === "" || this.Produtos.proPeso === null) {
                        this.Produtos.proPeso = 0;
                    }
                    else {
                        this.Produtos.proPeso = parseFloat(this.Produtos.proPeso);
                    }
                    if (this.Produtos.proAltura === "" || this.Produtos.proAltura === null) {
                        this.Produtos.proAltura = 0;
                    }
                    else {
                        this.Produtos.proAltura = parseFloat(this.Produtos.proAltura);
                    }
                    if (this.Produtos.proLargura === "" || this.Produtos.proLargura === null) {
                        this.Produtos.proLargura = 0;
                    }
                    else {
                        this.Produtos.proLargura = parseFloat(this.Produtos.proLargura);
                    }
                    if (this.Produtos.proProfundidade === "" || this.Produtos.proProfundidade === null) {
                        this.Produtos.proProfundidade = 0;
                    }
                    else {
                        this.Produtos.proProfundidade = parseFloat(this.Produtos.proProfundidade);
                    }
                    if (this.Produtos.proValorDe === "" || this.Produtos.proValorDe === null) {
                        this.Produtos.proValorDe = 0;
                    }
                    else {
                        this.Produtos.proValorDe = parseFloat(this.Produtos.proValorDe);
                    }
                    if (this.Produtos.proValorPor === "" || this.Produtos.proValorPor === null) {
                        this.Produtos.proValorPor = 0;
                    }
                    else {
                        this.Produtos.proValorPor = parseFloat(this.Produtos.proValorPor);
                    }
                    this.Produtos.admGrupoTelas = { GrtId: this.Produtos.grtId }
                    this.Produtos.admCadastroTelas = { CdtId: this.Produtos.cdtId }
                    this.Produtos.cadIdiomas = { IdiId: this.Produtos.idiId }

             
                axios.put(`/Process/ProProdutos/Update`, this.Produtos)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.proId;
                axios.delete(`/Process/ProProdutos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item          
            this.Produtos = item;
            //carrega lista de imagens
            axios.get(`/Process/ProListaGaleriaImagens/GetAllByCtiId/${this.Produtos.cdtId}`)
                                .then(response => {
                                    this.ListaProdAvImgs = response.data.filter(x => x.pliVisivel).sort((a, b) => {
                                                                                                    const valorA = parseInt(a.pliOrdem) || 0;
                                                                                                    const valorB = parseInt(b.pliOrdem) || 0;
                                                                                                    return valorA - valorB;
                                                                                                    });
                                
                                    if (this.ListaProdAvImgs!=''){
                                    this.CarregaImagens();
                                    }
                                });

            //Carrega lista grids
            //carrega lista de Grids
             axios.get(`/Process/ProListaGaleriaGrids/GetAllByCtiId/${this.Produtos.cdtId}`)
                    .then(response => {
                        this.ListaProdGrids = response.data.filter(x => x.plgVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.plgOrdem) || 0;
                                                                                          const valorB = parseInt(b.plgOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                        if (this.ListaProdGrids!=''){
                           this.CarregaGrids();
                        }
                    });
                    //carrega lista de Arquivos
                    axios.get(`/Process/ProListaGaleriaArquivos/GetAllByCtiId/${this.Produtos.cdtId}`)
                        .then(response => {
                        this.ListaProdArqs = response.data.filter(x => x.plaVisivel).sort((a, b) => {
                                                                                        const valorA = parseInt(a.plaOrdem) || 0;
                                                                                        const valorB = parseInt(b.plaOrdem) || 0;
                                                                                        return valorA - valorB;
                                                                                        });
                        if (this.ListaProdArqs!=''){
                        this.CarregaArquivos();
                        }
                    });

             // segmentos
             this.CarregaListaSegmentos();
             this.MostraSegmento = true;

             // lista Produtos Avulsos
             axios.get(`/Process/ProProdutosAvulsos/GetAllByCtiId/16`)
                        .then(response => {
                            this.ListaAvulsosGeral = response.data; 
                            if (this.ListaAvulsosGeral.idiId != ''){
                                this.CarregaAvulsos();
                            }       
                            this.MostraAvulsos = true;                    
                        });

             // carregar as tabelas de preços
             axios.get(`/Process/ProProdutosTabelaPrecos/GetAllByCtiId/20/1`)
                .then(response => {
                    this.ListaTabelaPreco = response.data;  
                    console.log(this.ListaTabelaPreco)
                    console.log(this.ListaTabelaPreco.length)
                    console.log(this.ListaTabelaPreco[0].ptcId)
                    if (this.ListaTabelaPreco.length == 1){
                        this.ProdutosPreco.ptcId = this.ListaTabelaPreco[0].ptcId;
                        this.CarregaListaProdutosPrecos(this.ProdutosPreco.ptcId)
                        this.motraProdutosPrecos = false;
                    }
                    this.MostraPrecos = true;      
                });
            
             this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "proId", sortable: true, });
            this.headers.push({ text: "Nome Produto", value: "proNomeProduto", sortable: true, });
            this.headers.push({ text: "Referência", value: "proReferencia", sortable: true, });
            this.headers.push({ text: "Visível", value: "proVisivel", sortable: true, });
            this.headers.push({ text: "Destaque", value: "proDestaque", sortable: true, });
            this.headers.push({ text: "Ordem", value: "proOrdem", sortable: true, });
           // this.headers.push({ text: "Valor De", value: "proValorDe", sortable: true, });
           // this.headers.push({ text: "Valor Por", value: "proValorPor", sortable: true, });     
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //produtos
        //segmentos
        AddSegmentos(value){
                if (this.ProdutosSegs.psgId == '' || this.ProdutosSegs.psgId == 0) {
                    this.$mensagemAviso("Campo Segmento Obrigatório.")
                }
                else{
                  var validexits = this.ListaSegmentos.filter(x=> x.proSegmentos.psgId == this.ProdutosSegs.psgId && x.proProdutos.proId == this.Produtos.proId);             
                  
                  if (validexits.length == 0){
 
                    if (this.ProdutosSegs.pssOrdem === "" || this.ProdutosSegs.pssOrdem === null) {
                        this.ProdutosSegs.pssOrdem = 0;
                    }
                    else {
                        this.ProdutosSegs.pssOrdem = parseInt(this.ProdutosSegs.pssOrdem);
                    }
                        this.ProdutosSegs.proProdutos = {ProId: value};
                        this.ProdutosSegs.ProSegmentos = {PsgId: this.ProdutosSegs.psgId};
                        axios.post(`/Process/ProProdutosSegmentos/Add`, this.ProdutosSegs)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Segmento Incluído.")
                                this.CarregaListaSegmentos();
                                this.ProdutosSegs.psgId = '';
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Registro Existente.")
                    }
                }
        },
        CarregaSegmentos(){
            axios.get(`/Process/ProProdutosSegmentos/GetByProId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ListaSegmentos = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.pssOrdem) || 0;
                                                                                const valorB = parseInt(b.pssOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                    this.ListaSegmentos =  this.itensComHierarquia(this.ListaSegmentos);  
                    });
        },
        CarregaListaSegmentos(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.ListaHierarquiaSeg = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel && x.idiId == this.Produtos.idiId);
        //------ carrega select psgIdPai com hierarquia
        function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
            const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
            const segmentosComCaminho = [];

            for (const segmentoFilho of segmentosFilhos) {
                const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                
                const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                segmentosComCaminho.push(...segmentosNetos);
            }

            return segmentosComCaminho;
            }
        
            const segmentosComCaminho = [];
            for (const segmento of this.ListaHierarquiaSeg.filter(x=> x.psgIdPai== 0)) {
            segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
            
            const segmentosFilhos = buildHierarchy(this.ListaHierarquiaSeg, segmento.psgId, segmento.psgNomeSegmento);
            segmentosComCaminho.push(...segmentosFilhos);
            }
            this.ListaHierarquiaSeg = segmentosComCaminho;   
            this.CarregaSegmentos();
        //------
        });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaHierarquiaSeg.filter(
            (x) => x.psgId === item.proSegmentos.psgId
            )[0]?.psgNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        DeleteSegmentos(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pssId;
                axios.delete(`/Process/ProProdutosSegmentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaListaSegmentos();
                    }
                });
            }
            });
        },
        //Segmentos
        //Produtos Avulsos
        async AddAvulsos(value){
            var validexits = this.ListaAvulsos.filter(x=> x.proProdutosAvulso.praId == this.ProdutosAvulsos.praId && x.proProdutos.proId == this.Produtos.proId);             
                  
                  if (validexits.length == 0){
                    this.ProdutosAvulsos.proProdutos = { ProId: value };
                    this.ProdutosAvulsos.proProdutosAvulso = { PraId: this.ProdutosAvulsos.praId }
                    axios.post(`/Process/ProAvulsosporProdutos/Add`, this.ProdutosAvulsos)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CarregaAvulsos();
                                this.ProdutosAvulsos.praId ='';
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Registro Existente.")
                    }
        },
        CarregaAvulsos(){
            axios.get(`/Process/ProAvulsosporProdutos/GetAllByProId/${this.Produtos.proId}`)
                    .then(response => {
                        this.ListaAvulsos = response.data.sort((a, b) => {
                                                                        const valorA = parseInt(a.papOrdem) || 0;
                                                                        const valorB = parseInt(b.papOrdem) || 0;
                                                                        return valorA - valorB;
                                                                    });
                    });
        },
        DeleteAvulsos(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.papId;
                axios.delete(`/Process/ProAvulsosporProdutos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaAvulsos();
                    }
                });
            }
            });
        },
        //Produtos Avulsos
        //Preco Produtos
        LimpaProdutosPreco(){
            this.ProdutosPreco.ppcId=0;
            this.ProdutosPreco.ptcId='';
            this.ProdutosPreco.ppcCampoExtra1= '';
            this.ProdutosPreco.ppcCampoExtra2= '';
            this.ProdutosPreco.ppcCampoExtra3= '';
            this.ProdutosPreco.ppcCampoExtra4= '';
            this.ProdutosPreco.ppcCampoExtra5= '';
            this.ProdutosPreco.ppcTexto1= '';
            this.ProdutosPreco.ppcTexto2= '';
            this.ProdutosPreco.ppcValorDe= '';
            this.ProdutosPreco.ppcValorPor= '';
            this.ProdutosPreco.ppcDisponivel= '';
            this.ProdutosPreco.ppcPadrao= '';
            this.ProdutosPreco.ppcOrdem= '';
            this.ProdutosPreco.ppcEstoque= '';
        },
        CarregaListaProdutosPrecos(value){
         if (value != null){
            axios.get(`/Process/ProProdutosPrecos/GetAllByPtcId/${value}/${this.Produtos.proId}`)
                        .then(response => {
                            this.ListaProdutosPrecos = response.data;  
                            this.LimpaProdutosPreco();
                            if (this.ListaProdutosPrecos!=''){
                                this.ProdutosPreco = this.ListaProdutosPrecos[0];
                                this.ProdutosPreco.ptcId = this.ListaProdutosPrecos[0].proProdutosTabelaPrecos.ptcId;
                            }
                            else{
                                console.log('aqui')
                                this.ProdutosPreco.pccId = 0;
                                this.ProdutosPreco.ptcId = value;
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Seleciona uma Tabela de Preço.")
                    }
        },
        AddProdutoPreco(value){
            value.ProProdutos = {ProId: this.Produtos.proId};
            value.ProProdutosTabelaPrecos = { PtcId: value.ptcId};
           if (value.ppcDisponivel === "" || value.ppcDisponivel === null) {
            value.ppcDisponivel = true;
           }

           if (value.ppcPadrao === "" || value.ppcPadrao === null) {
            value.ppcPadrao = false;
           }

           if (value.ppcOrdem === "" || value.ppcOrdem === null) {
            value.ppcOrdem = 0;
            }
            else {
                value.ppcOrdem = parseInt(value.ppcOrdem);
            }

            if (value.ppcEstoque === "" || value.ppcEstoque === null) {
                value.ppcEstoque = 0;
            }
            else {
                value.ppcEstoque = parseInt(value.ppcEstoque);
            }

            if (value.ppcValorDe === "") {
                value.ppcValorDe = null;
            }
            else {
                value.ppcValorDe = parseFloat(value.ppcValorDe)
            }

            if (value.ppcValorPor === "") {
                value.ppcValorPor = null;
            }
            else {
                value.ppcValorPor = parseFloat(value.ppcValorPor)
            }

            axios.post(`/Process/ProProdutosPrecos/Add`, value)
                        .then(response => {
                            if (response.data > 0) {
                                    this.ProdutosPreco.pccId = response.data;
                                    this.CarregaListaProdutosPrecos(value.ptcId);
                                    this.$mensagemAvisoSucesso("Registro Incluído")               
                            }
                        });
        },
        SalvaProdutosPrecos(value){
            value.ProProdutos = {ProId: this.Produtos.proId};
            value.ProProdutosTabelaPrecos = { PtcId: value.ptcId};
           if (value.ppcDisponivel === "" || value.ppcDisponivel === null) {
            value.ppcDisponivel = true;
           }

           if (value.ppcPadrao === "" || value.ppcPadrao === null) {
            value.ppcPadrao = false;
           }

           if (value.ppcOrdem === "" || value.ppcOrdem === null) {
            value.ppcOrdem = 0;
            }
            else {
                value.ppcOrdem = parseInt(value.ppcOrdem);
            }

            if (value.ppcEstoque === "" || value.ppcEstoque === null) {
                value.ppcEstoque = 0;
            }
            else {
                value.ppcEstoque = parseInt(value.ppcEstoque);
            }

            if (value.ppcValorDe === "") {
                value.ppcValorDe = null;
            }
            else {
                value.ppcValorDe = parseFloat(value.ppcValorDe)
            }

            if (value.ppcValorPor === "") {
                value.ppcValorPor = null;
            }
            else {
                value.ppcValorPor = parseFloat(value.ppcValorPor)
            }

            axios.put(`/Process/ProProdutosPrecos/Update`, value)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                             this.CarregaListaProdutosPrecos(value.ptcId);
                        }
                    });
        },
        //Preco Produtos
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Produtos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/ProProdutos/GetAll`) //GetAllByCtiId/14
                        .then(response => {
                            this.ListProdutosGeral = response.data; 
                            if (this.Produtos.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.Produtos.cdtId = 14;
                    this.Produtos.grtId = 3;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
