<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Configura galerias de imagens por Produtos</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ConfigImg.pliId" label="Id" variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-if="ListaTelas!=''">
                                    <v-select v-model="ConfigImg.cdtId" label="Telas" :items="ListaTelas"
                                        item-title="cdtNome" item-value="cdtId" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ConfigImg.pliNome" :rules="nameRules" :counter="200"
                                        label="Nome Galeria" variant="underlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-text-field v-model="ConfigImg.pliOrdem" label="Ordem" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select v-model="ConfigImg.pliVisivel" label="Visível" :items="Status"
                                        item-title="nome" item-value="pliVisivel" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.pliLargura" label="Largura px (width)" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.pliAltura" label="Altura px (height) " type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigImg.pliQtd" label="Quantidade" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ConfigImg.pliId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ConfigImgs)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-pliVisivel="item">
                            <span>{{ item.pliVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-pliOrdem="item">
                            <span>{{ parseInt(item.pliOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ConfigListaImagensTelasView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ConfigImg: {
            pliId: 0,
            cdtId: '',
            pliNome: '',
            pliOrdem: '',
            pliVisivel: '',
            pliLargura: '',
            pliAltura: '',
            pliQtd: '',
        },
        ConfigImgs: [],
        ListaTelas:[{ cdtId: 14, cdtNome: "Produtos" }],
        selectedVisivel: null,
        Status: [{ nome: "Sim", pliVisivel: true }, { nome: "Não", pliVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "pliId", sortable: true, },
            { text: "Tela", value: "admCadastroTelas.cdtNome", sortable: true, },
            { text: "Nome", value: "pliNome", sortable: true, },
            { text: "Ordem", value: "pliOrdem", sortable: true, },
            { text: "Visivel", value: "pliVisivel", sortable: true, },
            { text: "Largura", value: "pliLargura", sortable: true, },
            { text: "Altura", value: "pliAltura", sortable: true, },
            { text: "Qtde", value: "pliQtd", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                pliOrdem: parseInt(item.pliOrdem),
            };
            });
        },
        Limpar() {
            this.ConfigImg.pliId = 0;
            this.ConfigImg.cdtId = "";
            this.ConfigImg.pliNome = "";
            this.ConfigImg.pliOrdem = "";
            this.ConfigImg.pliVisivel = "";
            this.ConfigImg.pliLargura = "";
            this.ConfigImg.pliAltura = "";
            this.ConfigImg.pliQtd = "";
            this.selectedVisivel = "";
            this.Get();
        },
        Add() {
            if (this.ConfigImg.pliNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigImg.pliOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigImg.pliVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigImg.cdtId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigImg.pliAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigImg.pliLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigImg.pliQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigImg.pliOrdem = parseInt(this.ConfigImg.pliOrdem)
                this.ConfigImg.pliAltura = parseInt(this.ConfigImg.pliAltura)
                this.ConfigImg.pliLargura = parseInt(this.ConfigImg.pliLargura)
                this.ConfigImg.pliQtd = parseInt(this.ConfigImg.pliQtd)
                this.ConfigImg.AdmCadastroTelas = { cdtId: this.ConfigImg.cdtId};
                axios.post(`/Process/ProListaGaleriaImagens/Add`, this.ConfigImg)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.ConfigImg.pliNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigImg.pliOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigImg.pliVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigImg.cdtId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigImg.pliAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigImg.pliLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigImg.pliQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigImg.pliOrdem = parseInt(this.ConfigImg.pliOrdem)
                this.ConfigImg.pliAltura = parseInt(this.ConfigImg.pliAltura)
                this.ConfigImg.pliLargura = parseInt(this.ConfigImg.pliLargura)
                this.ConfigImg.pliQtd = parseInt(this.ConfigImg.pliQtd)
                this.ConfigImg.AdmCadastroTelas = { cdtId: this.ConfigImg.cdtId};
                axios.put(`/Process/ProListaGaleriaImagens/Update`, this.ConfigImg)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.ConfigImg = item;
            this.ConfigImg.cdtId = item.admCadastroTelas.cdtId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pliId;
                axios.delete(`/Process/ProListaGaleriaImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
                axios.get(`/Process/ProListaGaleriaImagens/GetAll`)
                .then(response => {
                    this.ConfigImgs = response.data;
                    console.log('this.ConfigImgs')
                    console.log(this.ConfigImgs)
                });
              
        }
    },
    created() {
        this.Get();

        /*axios.get(`Process/AdmCadastroTelas/GetTelasListaImage`)
                .then(response => {
                    this.ListaTelas = response.data.filter(x.);
                });*/
    },
}
</script>

<style></style>
