<template>
       <div>
        <div class="pd-ltr-20">
           <div class="faq-wrap">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <img src="/images/banner-img.png" alt="">
                                    </div>
                                    <div class="col-md-8" v-if="User!=''">
                                        <h4 class="font-20 weight-500 mb-10 text-capitalize">
                                            Olá, <div class="weight-600 font-30 text-blue">{{User.nome}}</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
//import moment from 'moment'

export default {
  name: 'HomeView',
  components: {
  },
  data: () => ({
    accordion: false,
    User:{},
  }),
  methods: {
    // formattedDate (value) {
    //    return this.$moment(value).format('DD/MM/YYYY')
    // }
  },
  created() {
    const valorArmazenado = localStorage.getItem('_userav');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);
    }
  },
}
</script>

<style>

</style>
