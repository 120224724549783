<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Configura telas institucionais</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-md-3" style="padding-top: 20px;border-right:1px solid #cccccc;">
                            <div class="form-group">
                                <v-select v-model="GrupoSelected" label="Selecione Grupo" :items="GrupoTelas"
                                    item-title="gtiNome" item-value="gtiId" variant="underlined"
                                    @update:modelValue="CarregaTelas(GrupoSelected)">
                                </v-select>
                            </div>
                            <div v-for="telas in CadastroTelas" :key="telas.ctiId"
                                style="padding-top: 7px;padding-bottom: 7px;">
                                <a href="#" @click="CarregaCampos(telas.ctiId, telas.ctiNome)">{{ telas.ctiNome }}</a>
                            </div>
                        </div>
                        <div class="col-md-9" style="padding-top: 20px;" v-if="CamposTela.ctiId != 0">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <h4>Tela: {{ NomeTela }} </h4>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="CamposTela.ftiId" label="Id"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="CamposTela.ctiId" label="Id"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="CamposTela.multi" color="primary" label="Multi-Documento"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiVisivel" color="primary" label="Visível"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiVisivelLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiVisivelCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiOrdem" color="primary" label="Ordem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiOrdemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiOrdemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiDestaque" color="primary" label="Destaque"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiDestaqueLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiDestaqueCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiDestaqueExtra" color="primary"
                                                label="Destaque Extra" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiDestaqueExtraLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiDestaqueExtraCol"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiNome" color="primary" label="Nome"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiNomeLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiNomeCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="CamposTela.itiReferencia" color="primary" label="Referência"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="CamposTela.itiReferenciaLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="CamposTela.itiReferenciaCol"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewCamposExtras" color="primary" label="Campos Extras"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra1" color="primary"
                                                label="Campo Extra 1" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra1Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra2" color="primary"
                                                label="Campo Extra 2" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra2Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra3" color="primary"
                                                label="Campo Extra 3" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra3Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra4" color="primary"
                                                label="Campo Extra 4" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra4Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra5" color="primary"
                                                label="Campo Extra 5" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra5Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra5Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra6" color="primary"
                                                label="Campo Extra 6" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra6Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra6Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra7" color="primary"
                                                label="Campo Extra 7" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra7Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra7Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra8" color="primary"
                                                label="Campo Extra 8" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra8Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra8Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra9" color="primary"
                                                label="Campo Extra 9" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra9Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra9Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-switch v-model="CamposTela.itiCampoExtra10" color="primary"
                                                label="Campo Extra 10" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra10Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCamposExtras">
                                            <v-text-field v-model="CamposTela.itiCampoExtra10Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewDescricao" color="primary" label="Descrições"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiResumo1" color="primary" label="Resumo 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiResumo1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiResumo1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiResumo2" color="primary" label="Resumo 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiResumo2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiResumo2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao1" color="primary" label="Descrição 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao1Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao2" color="primary" label="Descrição 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao2Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao3" color="primary" label="Descrição 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao3Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao4" color="primary" label="Descrição 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao4Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao5" color="primary" label="Descrição 5"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao5Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao5Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-switch v-model="CamposTela.itiDescricao6" color="primary" label="Descrição 6"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao6Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDescricao">
                                            <v-text-field v-model="CamposTela.itiDescricao6Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewBotoes" color="primary" label="Campos Botões e links"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiTextoBtn1" color="primary" label="Texto Btn 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiTextoBtn2" color="primary" label="Texto Btn 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiTextoBtn3" color="primary" label="Texto Btn 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn3Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiTextoBtn4" color="primary" label="Texto Btn 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiTextoBtn4Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiLinkBtn1" color="primary" label="Link Btn 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiLinkBtn2" color="primary" label="Link Btn 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiLinkBtn3" color="primary" label="Link Btn 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn3Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-switch v-model="CamposTela.itiLinkBtn4" color="primary" label="Link Btn 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewBotoes">
                                            <v-text-field v-model="CamposTela.itiLinkBtn4Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewCores" color="primary" label="Campos de Cores"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-switch v-model="CamposTela.itiCor1" color="primary" label="Cor 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-switch v-model="CamposTela.itiCor2" color="primary" label="Cor 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-switch v-model="CamposTela.itiCor3" color="primary" label="Cor 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor3Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-switch v-model="CamposTela.itiCor4" color="primary" label="Cor 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewCores">
                                            <v-text-field v-model="CamposTela.itiCor4Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewValores" color="primary" label="Campos de Valores"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-switch v-model="CamposTela.itiValor1" color="primary" label="Valor 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-switch v-model="CamposTela.itiValor2" color="primary" label="Valor 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-switch v-model="CamposTela.itiValor3" color="primary" label="Valor 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor3Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-switch v-model="CamposTela.itiValor4" color="primary" label="Valor 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewValores">
                                            <v-text-field v-model="CamposTela.itiValor4Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="ViewDatas" color="primary" label="Campos de Data"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-switch v-model="CamposTela.itiData1" color="primary" label="Data 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData1Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-switch v-model="CamposTela.itiData2" color="primary" label="Data 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData2Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-switch v-model="CamposTela.itiData3" color="primary" label="Data 3"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData3Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-switch v-model="CamposTela.itiData4" color="primary" label="Data 4"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="ViewDatas">
                                            <v-text-field v-model="CamposTela.itiData4Col" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="CamposTela.sgiSegmento" color="primary"
                                                label="Uso de Segmentos" hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiNomeSegmento" color="primary"
                                                label="Nome Segmento" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiNomeSegmentoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiNomeSegmentoCol"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiVisivel" color="primary" label="Visível"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiVisivelLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiVisivelCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiDestaque" color="primary" label="Destaque"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDestaqueLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDestaqueCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiOrdem" color="primary" label="Ordem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiOrdemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiOrdemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiResumo" color="primary" label="Resumo"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiResumoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiResumoCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiDescricao1" color="primary" label="Descriçao 1"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDescricao1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDescricao1Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.sgiDescricao2" color="primary" label="Descriçao 2"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDescricao2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.sgiSegmento">
                                            <v-text-field v-model="CamposTela.sgiDescricao2Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.isaSegmentoArq" color="primary"
                                                label="Segmentos com Arquivos" hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="12" v-if="CamposTela.sgiSegmento">
                                            <v-switch v-model="CamposTela.isaSegmentoImg" color="primary"
                                                label="Segmentos com Imagens" hide-details></v-switch>
                                        </v-col>
                                        <!---->

                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="CamposTela.insImagens" color="primary"
                                                label="Tela com Imagens" hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgUrlImagem" color="primary" label="Url Imagem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgUrlImagemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgUrlImagemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgNome" color="primary" label="Nome"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgNomeLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgNomeCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgOrdem" color="primary" label="Ordem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgOrdemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgOrdemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgVisivel" color="primary" label="Visível"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgVisivelLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgVisivelCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgAlt" color="primary" label="Alt"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgAltLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgAltCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-switch v-model="CamposTela.itgResumo" color="primary" label="Resumo"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgResumoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insImagens">
                                            <v-text-field v-model="CamposTela.itgResumoCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="CamposTela.insArquivos" color="primary"
                                                label="Tela com Arquivos" hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-switch v-model="CamposTela.iagUrlArquivo" color="primary" label="Url Arquivo"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagUrlArquivoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagUrlArquivoCol"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-switch v-model="CamposTela.iagNome" color="primary" label="Nome"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagNomeLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagNomeCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-switch v-model="CamposTela.iagOrdem" color="primary" label="Ordem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagOrdemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagOrdemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-switch v-model="CamposTela.iagVisivel" color="primary" label="Visível"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagVisivelLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagVisivelCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-switch v-model="CamposTela.iagResumo" color="primary" label="Resumo"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagResumoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insArquivos">
                                            <v-text-field v-model="CamposTela.iagResumoCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12">
                                            <v-switch v-model="CamposTela.insGrids" color="primary" label="Tela com Grids"
                                                hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsNome" color="primary" label="Nome"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsNomeLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsNomeCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsCampoExtra1" color="primary"
                                                label="Campo Extra 1" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra1Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra1Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsCampoExtra2" color="primary"
                                                label="Campo Extra 2" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra2Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra2Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsCampoExtra3" color="primary"
                                                label="Campo Extra 3" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra3Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra3Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsCampoExtra4" color="primary"
                                                label="Campo Extra 4" hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra4Label"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsCampoExtra4Col"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsOrdem" color="primary" label="Ordem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsOrdemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsOrdemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsVisivel" color="primary" label="Visível"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsVisivelLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsVisivelCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsResumo" color="primary" label="Resumo"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsResumoLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsResumoCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-switch v-model="CamposTela.igsUrlImagem" color="primary" label="Url Imagem"
                                                hide-details></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsUrlImagemLabel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="CamposTela.insGrids">
                                            <v-text-field v-model="CamposTela.igsUrlImagemCol" type="number"></v-text-field>
                                        </v-col>
                                        <!---->
                                        <v-col cols="12" md="12" v-if="CamposTela.insGrids" style="display:none;">
                                            <v-switch v-model="CamposTela.insGridImg" color="primary"
                                                label="Grids com imagens" hide-details></v-switch>
                                        </v-col>
                                        <!---->
                                    </v-row>
                                </v-container>
                            </v-form>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 40px; justify-content: center;">
                        <form>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <button type="button" @click="Limpar()" class="btn btn-link">
                                        Cancelar
                                    </button>
                                    <button v-if="CamposTela.ftiId != 0" type="button" class="btn btn-success"
                                        @click="Put()">
                                        Alterar
                                    </button>
                                    <button v-else type="button" class="btn btn-success" @click="Add()">
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ConfigInstitucionalView',
    components: {
        EasyDataTable
    },
    data: () => ({
        GrupoTelas: [],
        CadastroTelas: [],
        CamposTelas: [],
        CamposTela: {
            ftiId: 0
            , ctiId: 0
            , multi: false
            , itiVisivel: false
            , itiVisivelLabel: ''
            , itiVisivelCol: 0
            , itiOrdem: false
            , itiOrdemLabel: ''
            , itiOrdemCol: 0
            , itiDestaque: false
            , itiDestaqueLabel: ''
            , itiDestaqueCol: 0
            , itiDestaqueExtra: false
            , itiDestaqueExtraLabel: ''
            , itiDestaqueExtraCol: 0
            , itiNome: false
            , itiNomeLabel: ''
            , itiNomeCol: 0
            , itiReferencia: false
            , itiReferenciaLabel: ''
            , itiReferenciaCol: 0
            , itiCampoExtra1: false
            , itiCampoExtra1Label: ''
            , itiCampoExtra1Col: 0
            , itiCampoExtra2: false
            , itiCampoExtra2Label: ''
            , itiCampoExtra2Col: 0
            , itiCampoExtra3: false
            , itiCampoExtra3Label: ''
            , itiCampoExtra3Col: 0
            , itiCampoExtra4: false
            , itiCampoExtra4Label: ''
            , itiCampoExtra4Col: 0
            , itiCampoExtra5: false
            , itiCampoExtra5Label: ''
            , itiCampoExtra5Col: 0
            , itiCampoExtra6: false
            , itiCampoExtra6Label: ''
            , itiCampoExtra6Col: 0
            , itiCampoExtra7: false
            , itiCampoExtra7Label: ''
            , itiCampoExtra7Col: 0
            , itiCampoExtra8: false
            , itiCampoExtra8Label: ''
            , itiCampoExtra8Col: 0
            , itiCampoExtra9: false
            , itiCampoExtra9Label: ''
            , itiCampoExtra9Col: 0
            , itiCampoExtra10: false
            , itiCampoExtra10Label: ''
            , itiCampoExtra10Col: 0
            , itiResumo1: false
            , itiResumo1Label: ''
            , itiResumo1Col: 0
            , itiResumo2: false
            , itiResumo2Label: ''
            , itiResumo2Col: 0
            , itiDescricao1: false
            , itiDescricao1Label: ''
            , itiDescricao1Col: 0
            , itiDescricao2: false
            , itiDescricao2Label: ''
            , itiDescricao2Col: 0
            , itiDescricao3: false
            , itiDescricao3Label: ''
            , itiDescricao3Col: 0
            , itiDescricao4: false
            , itiDescricao4Label: ''
            , itiDescricao4Col: 0
            , itiDescricao5: false
            , itiDescricao5Label: ''
            , itiDescricao5Col: 0
            , itiDescricao6: false
            , itiDescricao6Label: ''
            , itiDescricao6Col: 0
            , itiTextoBtn1: false
            , itiTextoBtn1Label: ''
            , itiTextoBtn1Col: 0
            , itiTextoBtn2: false
            , itiTextoBtn2Label: ''
            , itiTextoBtn2Col: 0
            , itiTextoBtn3: false
            , itiTextoBtn3Label: ''
            , itiTextoBtn3Col: 0
            , itiTextoBtn4: false
            , itiTextoBtn4Label: ''
            , itiTextoBtn4Col: 0
            , itiLinkBtn1: false
            , itiLinkBtn1Label: ''
            , itiLinkBtn1Col: 0
            , itiLinkBtn2: false
            , itiLinkBtn2Label: ''
            , itiLinkBtn2Col: 0
            , itiLinkBtn3: false
            , itiLinkBtn3Label: ''
            , itiLinkBtn3Col: 0
            , itiLinkBtn4: false
            , itiLinkBtn4Label: ''
            , itiLinkBtn4Col: 0
            , itiCor1: false
            , itiCor1Label: ''
            , itiCor1Col: 0
            , itiCor2: false
            , itiCor2Label: ''
            , itiCor2Col: 0
            , itiCor3: false
            , itiCor3Label: ''
            , itiCor3Col: 0
            , itiCor4: false
            , itiCor4Label: ''
            , itiCor4Col: 0
            , itiValor1: false
            , itiValor1Label: ''
            , itiValor1Col: 0
            , itiValor2: false
            , itiValor2Label: ''
            , itiValor2Col: 0
            , itiValor3: false
            , itiValor3Label: ''
            , itiValor3Col: 0
            , itiValor4: false
            , itiValor4Label: ''
            , itiValor4Col: 0
            , itiData1: false
            , itiData1Label: ''
            , itiData1Col: 0
            , itiData2: false
            , itiData2Label: ''
            , itiData2Col: 0
            , itiData3: false
            , itiData3Label: ''
            , itiData3Col: 0
            , itiData4: false
            , itiData4Label: ''
            , itiData4Col: 0
            , sgiSegmento: false
            , sgiNomeSegmento: false
            , sgiNomeSegmentoLabel: ''
            , sgiNomeSegmentoCol: 0
            , sgiVisivel: false
            , sgiVisivelLabel: ''
            , sgiVisivelCol: 0
            , sgiDestaque: false
            , sgiDestaqueLabel: ''
            , sgiDestaqueCol: 0
            , sgiOrdem: false
            , sgiOrdemLabel: ''
            , sgiOrdemCol: 0
            , sgiResumo: false
            , sgiResumoLabel: ''
            , sgiResumoCol: 0
            , sgiDescricao1: false
            , sgiDescricao1Label: ''
            , sgiDescricao1Col: 0
            , sgiDescricao2: false
            , sgiDescricao2Label: ''
            , sgiDescricao2Col: 0
            , isaSegmentoArq: false
            , isaSegmentoImg: false
            , insArquivos: false
            , iagUrlArquivo: false
            , iagUrlArquivoLabel: ''
            , iagUrlArquivoCol: 0
            , iagOrdem: false
            , iagOrdemLabel: ''
            , iagOrdemCol: 0
            , iagNome: false
            , iagNomeLabel: ''
            , iagNomeCol: 0
            , iagResumo: false
            , iagResumoLabel: ''
            , iagResumoCol: 0
            , iagVisivel: false
            , iagVisivelLabel: ''
            , iagVisivelCol: 0
            , insImagens: false
            , itgUrlImagem: false
            , itgUrlImagemLabel: ''
            , itgUrlImagemCol: 0
            , itgOrdem: false
            , itgOrdemLabel: ''
            , itgOrdemCol: 0
            , itgAlt: false
            , itgAltLabel: ''
            , itgAltCol: 0
            , itgNome: false
            , itgNomeLabel: ''
            , itgNomeCol: 0
            , itgResumo: false
            , itgResumoLabel: ''
            , itgResumoCol: 0
            , itgVisivel: false
            , itgVisivelLabel: ''
            , itgVisivelCol: 0
            , insGrids: false
            , igsUrlImagem: false
            , igsUrlImagemLabel: ''
            , igsUrlImagemCol: 0
            , igsOrdem: false
            , igsOrdemLabel: ''
            , igsOrdemCol: 0
            , igsNome: false
            , igsNomeLabel: ''
            , igsNomeCol: 0
            , igsResumo: false
            , igsResumoLabel: ''
            , igsResumoCol: 0
            , igsCampoExtra1: false
            , igsCampoExtra1Label: ''
            , igsCampoExtra1Col: 0
            , igsCampoExtra2: false
            , igsCampoExtra2Label: ''
            , igsCampoExtra2Col: 0
            , igsCampoExtra3: false
            , igsCampoExtra3Label: ''
            , igsCampoExtra3Col: 0
            , igsCampoExtra4: false
            , igsCampoExtra4Label: ''
            , igsCampoExtra4Col: 0
            , igsVisivel: false
            , igsVisivelLabel: ''
            , igsVisivelCol: 0
            , insGridImg: false
        },
        GrupoSelected: null,
        NomeTela: '',
        ViewCamposExtras: false,
        ViewDescricao: false,
        ViewBotoes: false,
        ViewCores: false,
        ViewValores: false,
        ViewDatas: false,
    }),
    methods: {
        Limpar() {
            this.CamposTela = {
                ftiId: 0
                , ctiId: 0
                , multi: false
                , itiVisivel: false
                , itiVisivelLabel: ''
                , itiVisivelCol: 0
                , itiOrdem: false
                , itiOrdemLabel: ''
                , itiOrdemCol: 0
                , itiDestaque: false
                , itiDestaqueLabel: ''
                , itiDestaqueCol: 0
                , itiDestaqueExtra: false
                , itiDestaqueExtraLabel: ''
                , itiDestaqueExtraCol: 0
                , itiNome: false
                , itiNomeLabel: ''
                , itiNomeCol: 0
                , itiReferencia: false
                , itiReferenciaLabel: ''
                , itiReferenciaCol: 0
                , itiCampoExtra1: false
                , itiCampoExtra1Label: ''
                , itiCampoExtra1Col: 0
                , itiCampoExtra2: false
                , itiCampoExtra2Label: ''
                , itiCampoExtra2Col: 0
                , itiCampoExtra3: false
                , itiCampoExtra3Label: ''
                , itiCampoExtra3Col: 0
                , itiCampoExtra4: false
                , itiCampoExtra4Label: ''
                , itiCampoExtra4Col: 0
                , itiCampoExtra5: false
                , itiCampoExtra5Label: ''
                , itiCampoExtra5Col: 0
                , itiCampoExtra6: false
                , itiCampoExtra6Label: ''
                , itiCampoExtra6Col: 0
                , itiCampoExtra7: false
                , itiCampoExtra7Label: ''
                , itiCampoExtra7Col: 0
                , itiCampoExtra8: false
                , itiCampoExtra8Label: ''
                , itiCampoExtra8Col: 0
                , itiCampoExtra9: false
                , itiCampoExtra9Label: ''
                , itiCampoExtra9Col: 0
                , itiCampoExtra10: false
                , itiCampoExtra10Label: ''
                , itiCampoExtra10Col: 0
                , itiResumo1: false
                , itiResumo1Label: ''
                , itiResumo1Col: 0
                , itiResumo2: false
                , itiResumo2Label: ''
                , itiResumo2Col: 0
                , itiDescricao1: false
                , itiDescricao1Label: ''
                , itiDescricao1Col: 0
                , itiDescricao2: false
                , itiDescricao2Label: ''
                , itiDescricao2Col: 0
                , itiDescricao3: false
                , itiDescricao3Label: ''
                , itiDescricao3Col: 0
                , itiDescricao4: false
                , itiDescricao4Label: ''
                , itiDescricao4Col: 0
                , itiDescricao5: false
                , itiDescricao5Label: ''
                , itiDescricao5Col: 0
                , itiDescricao6: false
                , itiDescricao6Label: ''
                , itiDescricao6Col: 0
                , itiTextoBtn1: false
                , itiTextoBtn1Label: ''
                , itiTextoBtn1Col: 0
                , itiTextoBtn2: false
                , itiTextoBtn2Label: ''
                , itiTextoBtn2Col: 0
                , itiTextoBtn3: false
                , itiTextoBtn3Label: ''
                , itiTextoBtn3Col: 0
                , itiTextoBtn4: false
                , itiTextoBtn4Label: ''
                , itiTextoBtn4Col: 0
                , itiLinkBtn1: false
                , itiLinkBtn1Label: ''
                , itiLinkBtn1Col: 0
                , itiLinkBtn2: false
                , itiLinkBtn2Label: ''
                , itiLinkBtn2Col: 0
                , itiLinkBtn3: false
                , itiLinkBtn3Label: ''
                , itiLinkBtn3Col: 0
                , itiLinkBtn4: false
                , itiLinkBtn4Label: ''
                , itiLinkBtn4Col: 0
                , itiCor1: false
                , itiCor1Label: ''
                , itiCor1Col: 0
                , itiCor2: false
                , itiCor2Label: ''
                , itiCor2Col: 0
                , itiCor3: false
                , itiCor3Label: ''
                , itiCor3Col: 0
                , itiCor4: false
                , itiCor4Label: ''
                , itiCor4Col: 0
                , itiValor1: false
                , itiValor1Label: ''
                , itiValor1Col: 0
                , itiValor2: false
                , itiValor2Label: ''
                , itiValor2Col: 0
                , itiValor3: false
                , itiValor3Label: ''
                , itiValor3Col: 0
                , itiValor4: false
                , itiValor4Label: ''
                , itiValor4Col: 0
                , itiData1: false
                , itiData1Label: ''
                , itiData1Col: 0
                , itiData2: false
                , itiData2Label: ''
                , itiData2Col: 0
                , itiData3: false
                , itiData3Label: ''
                , itiData3Col: 0
                , itiData4: false
                , itiData4Label: ''
                , itiData4Col: 0
                , sgiSegmento: false
                , sgiNomeSegmento: false
                , sgiNomeSegmentoLabel: ''
                , sgiNomeSegmentoCol: 0
                , sgiVisivel: false
                , sgiVisivelLabel: ''
                , sgiVisivelCol: 0
                , sgiDestaque: false
                , sgiDestaqueLabel: ''
                , sgiDestaqueCol: 0
                , sgiOrdem: false
                , sgiOrdemLabel: ''
                , sgiOrdemCol: 0
                , sgiResumo: false
                , sgiResumoLabel: ''
                , sgiResumoCol: 0
                , sgiDescricao1: false
                , sgiDescricao1Label: ''
                , sgiDescricao1Col: 0
                , sgiDescricao2: false
                , sgiDescricao2Label: ''
                , sgiDescricao2Col: 0
                , isaSegmentoArq: false
                , isaSegmentoImg: false
                , insArquivos: false
                , iagUrlArquivo: false
                , iagUrlArquivoLabel: ''
                , iagUrlArquivoCol: 0
                , iagOrdem: false
                , iagOrdemLabel: ''
                , iagOrdemCol: 0
                , iagNome: false
                , iagNomeLabel: ''
                , iagNomeCol: 0
                , iagResumo: false
                , iagResumoLabel: ''
                , iagResumoCol: 0
                , iagVisivel: false
                , iagVisivelLabel: ''
                , iagVisivelCol: 0
                , insImagens: false
                , itgUrlImagem: false
                , itgUrlImagemLabel: ''
                , itgUrlImagemCol: 0
                , itgOrdem: false
                , itgOrdemLabel: ''
                , itgOrdemCol: 0
                , itgAlt: false
                , itgAltLabel: ''
                , itgAltCol: 0
                , itgNome: false
                , itgNomeLabel: ''
                , itgNomeCol: 0
                , itgResumo: false
                , itgResumoLabel: ''
                , itgResumoCol: 0
                , itgVisivel: false
                , itgVisivelLabel: ''
                , itgVisivelCol: 0
                , insGrids: false
                , igsUrlImagem: false
                , igsUrlImagemLabel: ''
                , igsUrlImagemCol: 0
                , igsOrdem: false
                , igsOrdemLabel: ''
                , igsOrdemCol: 0
                , igsNome: false
                , igsNomeLabel: ''
                , igsNomeCol: 0
                , igsResumo: false
                , igsResumoLabel: ''
                , igsResumoCol: 0
                , igsCampoExtra1: false
                , igsCampoExtra1Label: ''
                , igsCampoExtra1Col: 0
                , igsCampoExtra2: false
                , igsCampoExtra2Label: ''
                , igsCampoExtra2Col: 0
                , igsCampoExtra3: false
                , igsCampoExtra3Label: ''
                , igsCampoExtra3Col: 0
                , igsCampoExtra4: false
                , igsCampoExtra4Label: ''
                , igsCampoExtra4Col: 0
                , igsVisivel: false
                , igsVisivelLabel: ''
                , igsVisivelCol: 0
                , insGridImg: false
            };
            this.ViewCamposExtras = false;
            this.ViewDescricao = false;
            this.ViewBotoes = false;
            this.ViewCores = false;
            this.ViewValores = false;
            this.ViewDatas = false;
        },
        CarregaTelas(value) {
            axios.get(`/Process/AdmCadastroTelasInst/GetAll`)
                .then(response => {
                    this.CadastroTelas = response.data.filter(x => x.ctiVisivel && x.admGrupoTelasInst.gtiId == value).sort((a, b) => {
                        const valorA = parseInt(a.ctiOrdem) || 0;
                        const valorB = parseInt(b.ctiOrdem) || 0;
                        return valorA - valorB;
                    });
                });
        },
        CarregaCampos(value, name) {
            const param1 = value;
            axios.get(`/Process/AdmConfigTelaInstitucional/GetByCtiId/${param1}`)
                .then(response => {
                    if (response.data != '') {
                        this.Limpar();
                        this.CamposTela = response.data;
                        this.NomeTela = name;

                        if (this.CamposTela.itiResumo1 || this.CamposTela.itiResumo2 ||
                            this.CamposTela.itiDescricao1 || this.CamposTela.itiDescricao2 ||
                            this.CamposTela.itiDescricao3 || this.CamposTela.itiDescricao4 ||
                            this.CamposTela.itiDescricao5 || this.CamposTela.itiDescricao6) {

                            this.ViewDescricao = true;
                        }

                        if (this.CamposTela.itiCampoExtra1 || this.CamposTela.itiCampoExtra2 ||
                            this.CamposTela.itiCampoExtra3 || this.CamposTela.itiCampoExtra4 ||
                            this.CamposTela.itiCampoExtra5 || this.CamposTela.itiCampoExtra6 ||
                            this.CamposTela.itiCampoExtra7 || this.CamposTela.itiCampoExtra8 ||
                            this.CamposTela.itiCampoExtra9 || this.CamposTela.itiCampoExtra10) {

                            this.ViewCamposExtras = true;
                        }

                        if (this.CamposTela.itiTextoBtn1 || this.CamposTela.itiTextoBtn2 ||
                            this.CamposTela.itiTextoBtn3 || this.CamposTela.itiTextoBtn4 ||
                            this.CamposTela.itiLinkBtn1 || this.CamposTela.itiLinkBtn2 ||
                            this.CamposTela.itiLinkBtn3 || this.CamposTela.itiLinkBtn4) {

                            this.ViewBotoes = true;
                        }

                        if (this.CamposTela.itiCor1 || this.CamposTela.itiCor2 ||
                            this.CamposTela.itiCor3 || this.CamposTela.itiCor4) {

                            this.ViewCores = true;
                        }

                        if (this.CamposTela.itiValor1 || this.CamposTela.itiValor2 ||
                            this.CamposTela.itiValor3 || this.CamposTela.itiValor4) {

                            this.ViewValores = true;
                        }

                        if (this.CamposTela.itiData1 || this.CamposTela.itiData2 ||
                            this.CamposTela.itiData3 || this.CamposTela.itiData4) {

                            this.ViewDatas = true;
                        }



                    }
                    else {
                        this.Limpar();
                        this.CamposTela.ctiId = param1;
                        this.NomeTela = name;
                    }
                });
        },
        Add() {
            if (this.CamposTela.CtiId == 0) {
                this.$mensagemAviso("Escolha uma tela.")
            }
            else {
                // Crie uma nova cópia do objeto this.CamposTela usando o spread operator
                const camposTelaAtualizado = { ...this.CamposTela };

                Object.entries(camposTelaAtualizado).forEach(([chave, valor]) => {
                    // Verifique se a chave termina com "Col"
                    if (chave.endsWith("Col")) {
                        // Verifique se o valor é uma string numérica
                        if (typeof valor === "string" && /^\d+$/.test(valor)) {
                            // Se for uma string numérica, converta para um número inteiro
                            camposTelaAtualizado[chave] = parseInt(valor, 10);
                        } else if (typeof valor === "string" && valor.includes(",")) {
                            // Se for uma string contendo vários números separados por vírgula
                            // Divida a string em um array e converta cada item em um número inteiro
                            camposTelaAtualizado[chave] = valor.split(",").map((item) => parseInt(item.trim(), 10));
                        }
                    }
                });

                // Atribua o objeto camposTelaAtualizado de volta a this.CamposTela
                this.CamposTela = camposTelaAtualizado;

                axios.post(`/Process/AdmConfigTelaInstitucional/Add`, this.CamposTela)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Atualizado.")
                            //this.Get();
                            //this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.CamposTela.CtiId == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else {

                // Crie uma nova cópia do objeto this.CamposTela usando o spread operator
                const camposTelaAtualizado = { ...this.CamposTela };

                Object.entries(camposTelaAtualizado).forEach(([chave, valor]) => {
                    // Verifique se a chave termina com "Col"
                    if (chave.endsWith("Col")) {
                        // Verifique se o valor é uma string numérica
                        if (typeof valor === "string" && /^\d+$/.test(valor)) {
                            // Se for uma string numérica, converta para um número inteiro
                            camposTelaAtualizado[chave] = parseInt(valor, 10);
                        } else if (typeof valor === "string" && valor.includes(",")) {
                            // Se for uma string contendo vários números separados por vírgula
                            // Divida a string em um array e converta cada item em um número inteiro
                            camposTelaAtualizado[chave] = valor.split(",").map((item) => parseInt(item.trim(), 10));
                        }
                    }
                });

                // Atribua o objeto camposTelaAtualizado de volta a this.CamposTela
                this.CamposTela = camposTelaAtualizado;

                axios.put(`/Process/AdmConfigTelaInstitucional/Update`, this.CamposTela)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Atualizado.")
                            //this.Get();
                            //this.Limpar();
                        }
                    });
            }
        },
        Get() {
            axios.get(`/Process/AdmGrupoTelasInst/GetAll`)
                .then(response => {
                    this.GrupoTelas = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
