<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de estados</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Estado.cetId" label="Id" variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="Estado.cetNome"
                                        label="Nome" variant="underlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field v-model="Estado.cetSigla"
                                        label="Sigla" variant="underlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select v-model="Estado.cetAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="cetAtivo" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4" v-if="Paises!=''">
                                    <v-select v-model="Estado.cpaId" label="Paises" :items="Paises"
                                        item-title="cpaNome" item-value="cpaId" variant="underlined">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Estado.cetId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Estados" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cetAtivo="item">
                            <span>{{ item.cetAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'CadastroEstadosView',
    components: {
        EasyDataTable
    },
    data: () => ({
        Estado: {
            cetId: 0,
            cetNome: '',
            cetSigla: '',
            cetAtivo: '',
            cpaId: '',
        },
        Estados: [],
        StatusAtivo: [{ nome: "Sim", cetAtivo: true }, { nome: "Não", cetAtivo: false }],
        headers: [
            { text: "Id", value: "cetId", sortable: true, },
            { text: "Nome", value: "cetNome", sortable: true, },
            { text: "Sigla", value: "cetSigla", sortable: true, },
            { text: "Ativo", value: "cetAtivo", sortable: true, },
            { text: "Pais", value: "cadPaises.cpaNome", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        selectedPaises:null,
        Paises:[],
        searchValue: '',
    }),
    methods: {
        Limpar() {
            this.Estado.cetId = 0;
            this.Estado.cetNome = '';
            this.Estado.cetSigla = '';
            this.Estado.cetAtivo = '';
            this.Estado.cpaId = '';
            this.selectedPaises = null;
            this.Get();
        },
        Add() {
            if (this.Estado.cetNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Estado.cetSigla == ''){
                this.$mensagemAviso("Sigla Obrigatória.")
            }
            else if (this.selectedPaises == ''){
                this.$mensagemAviso("País Obrigatório.")
            }
            else {
                this.Estado.CadPaises = { cpaId: this.Estado.cpaId};
                if (this.Estado.cetAtivo === "" || this.Estado.cetAtivo === null){
                    this.Estado.cetAtivo = true;
                }
                axios.post(`/Process/CadEstados/Add`, this.Estado)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.Estado.cetNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Estado.cetSigla == ''){
                this.$mensagemAviso("Sigla Obrigatória.")
            }
            else if (this.selectedPaises == ''){
                this.$mensagemAviso("País Obrigatório.")
            }
            else {
                this.Estado.cadPaises = { cpaId: this.Estado.cpaId};
                if (this.Estado.cetAtivo === "" || this.Estado.cetAtivo === null){
                    this.Estado.cetAtivo = true;
                }
                axios.put(`/Process/CadEstados/Update`, this.Estado)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.Estado = item;
            this.Estado.cpaId = item.cadPaises.cpaId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cetId;
                axios.delete(`/Process/CadEstados/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CadEstados/GetAll`)
                .then(response => {
                    this.Estados = response.data;
                });
        }
    },
    created() {
        this.Get();

        axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.filter(x => x.cpaVisivel).sort((a, b) => {
                                                                                    const nomeA = a.cpaNome || ""; 
                                                                                    const nomeB = b.cpaNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                });
    },
}
</script>

<style></style>
