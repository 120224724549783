<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de usuários</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Usuario.cusId" label="Id" variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field v-model="Usuario.cusNome" label="Nome" variant="underlined"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="Usuario.cusEmail" label="E-mail" variant="underlined"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusSenha" :type="senhaVisivel ? 'text' : 'password'"
                                        label="Senha" variant="underlined"
                                        :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="toggleSenhaVisivel"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusTelefone" label="Telefone" variant="underlined"
                                        v-mask="'(##) ####-#####'" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Usuario.cusWhatsapp" label="Whatsapp" variant="underlined"
                                        v-mask="'(##) ####-#####'" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select v-model="Usuario.cusAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="cusAtivo" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3" v-if="TipoUsuario != ''">
                                    <v-select v-model="Usuario.tusId" label="Tipo Usuário" :items="TipoUsuario"
                                        item-title="tusNome" item-value="tusId" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-file-input v-model="selectedFile" label="Imagem" variant="underlined"
                                        prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Usuario.cusUrlImagem !== '' && Usuario.cusUrlImagem !== null">
                                    <img :src="$imgURL + Usuario.cusUrlImagem" style="width: 100px" />
                                    <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                        <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Usuario.cusId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Usuarios" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cusUrlImagem="item">
                            <img v-if="item.cusUrlImagem!='' && item.cusUrlImagem!=null" :src="$imgURL + item.cusUrlImagem" style="width: 40px;height:auto;border-radius:50px" />
                            <img v-else src="/images/userNo.png" style="width: 40px;height:auto;border-radius:50px" />
                        </template>
                        <template #item-cusAtivo="item">
                            <span>{{ item.cusAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cusDtCadastro="item">
                            <span>{{ formattedDate(item.cusDtCadastro) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'


export default {
    name: 'CadastroUsuariosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        Usuario: {
            cusId: 0,
            cusNome: '',
            cusEmail: '',
            cusTelefone: '',
            cusWhatsapp: '',
            cusSenha: '',
            cusDtCadastro: null,
            tusId: '',
            cusAtivo: '',
            cusUrlImagem: '',
        },
        Usuarios: [],
        TipoUsuario: [],
        StatusAtivo: [{ nome: "Sim", cusAtivo: true }, { nome: "Não", cusAtivo: false }],
        headers: [
            { text: "Id", value: "cusId", sortable: true, },
            { text: "Imagem", value: "cusUrlImagem", },
            { text: "Nome", value: "cusNome", sortable: true, },
            { text: "Email", value: "cusEmail", sortable: true, },
            { text: "Telefone", value: "cusTelefone", },
            { text: "Whatsapp", value: "cusWhatsapp", },
            { text: "Data Cadastro", value: "cusDtCadastro",  },
            { text: "Tipo", value: "admTipoUsuario.tusNome", sortable: true, },
            { text: "Ativo", value: "cusAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        searchValue: '',
        itemsSelected: null,
        senhaVisivel: false,
        selectedFile: null,
    }),
    methods: {
        formattedDate(value) {
            return this.$moment(value).format('DD/MM/YYYY')
        },
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
        Limpar() {
            this.Usuario.cusId = 0;
            this.Usuario.cusNome = '';
            this.Usuario.cusEmail = '';
            this.Usuario.cusTelefone = '';
            this.Usuario.cusWhatsapp = '';
            this.Usuario.cusSenha = '';
            this.Usuario.cusDtCadastro = '';
            this.Usuario.tusId = '';
            this.Usuario.cusAtivo = '';
            this.Usuario.cusUrlImagem = '';
            this.selectedFile = null;
            this.Get();
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
            if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.Usuario.tusId == '' || this.Usuario.tusId == 0) {
                this.$mensagemAviso("Tipo Usuário Obrigatório.")
            }
            else if (this.Usuario.cusAtivo === ''){
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                this.Usuario.admTipoUsuario = { tusId: this.Usuario.tusId };
                this.Usuario.cusDtCadastro = moment();
                if (this.Usuario.cusAtivo === "" || this.Usuario.cusAtivo === null){
                    this.Usuario.cusAtivo = true;
                }
                if (this.selectedFile != '' && this.selectedFile != null) {
                    console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                            .then(response => {
                                if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Usuario.cusUrlImagem = "";
                    axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }
            }
        },
        async Put() {
            if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.Usuario.tusId == '' || this.Usuario.tusId == 0) {
                this.$mensagemAviso("Tipo Usuário Obrigatório.")
            }
            else if (this.Usuario.cusAtivo === ''){
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                this.Usuario.admTipoUsuario = { tusId: this.Usuario.tusId };
                if (this.Usuario.cusAtivo === "" || this.Usuario.cusAtivo === null){
                    this.Usuario.cusAtivo = true;
                }
                if (this.selectedFile != '' && this.selectedFile != null) {
                    console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    if (this.Usuario.cusUrlImagem!= ""){
                        this.Usuario.cusUrlImagem = "0";
                    }
                    axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }
            }
        },
        EditGrid(item) {
            // Lógica para editar o item          
            this.Usuario = item;
            this.Usuario.tusId = item.admTipoUsuario.tusId
            this.$scrollToTop();
        },
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        const param1 = item.cusId;
                        axios.delete(`/Process/UsrCadastroUsuarios/Delete/${param1}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Excluido.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                });
        },
        DeleteImagem(){
            this.Usuario.cusUrlImagem = "";
            /*this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        axios.post(`/Process/UploadImg/delete/${value}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Imagem Excluida.")
                                    
                                }
                            });
                    }
                });*/
        },
        Get() {
            axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                .then(response => {
                    this.Usuarios = response.data.filter(x=> x.cusId != 2);
                });
        }
    },
    created() {
        this.Get();

        axios.get(`/Process/AdmTipoUsuario/GetAll`)
            .then(response => {
                this.TipoUsuario = response.data.filter(x => x.tusVisivel).sort((a, b) => {
                    const nomeA = a.tusNome || "";
                    const nomeB = b.tusNome || "";
                    return nomeA.localeCompare(nomeB);
                });
            });
    },
}
</script>

<style></style>
