<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                        <v-tab value="dados" class="tabinfo">Detalhe Pedido</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container v-if="PedidosCli!='' && PedidosAgendamento!='' && PedidosEndereco !='' && Cidades!='' && Estados!='' && Bairros!=''">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Endereço Entrega</h5>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Endereço:</strong> {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{ PedidosEndereco[0].pdcComplemento }} </p>
                                                          <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Cidade/UF:</strong> {{ Cidades.filter(x=> x.cidId == PedidosEndereco[0].cidId)[0].cidNome }} / {{ Estados.filter(x=> x.cetId == PedidosEndereco[0].cetId)[0].cetSigla}}</p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x=> x.baiId == PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro  }} </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Situação</h5>
                                                            <v-row>
                                                                <v-select v-model="PedidosCli.pspId" label="Situação Atual" :items="Situacoes"
                                                                    item-title="pspSituacao" item-value="pspId" variant="underlined">
                                                                </v-select>
                                                            </v-row>
                                                            <v-row style="justify-content: center;padding-top: 15px;">
                                                                <button type="button" class="btn btn-success" @click="PutSituacao()">
                                                                    Alterar
                                                                </button>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Mensagem</h5>
                                                            <div v-if="PedidosMensagem.length > 0 && PedidosMensagem[0].pmsNomeDe !=''">
                                                                <div v-for="pedidomensagem in PedidosMensagem" :key="pedidomensagem.pmsId" class="row">
                                                                    <div v-if="pedidomensagem.pmsTexto != ''" class="col">
                                                                        <div class="descricao-pedido border-top py-3">
                                                                            <div class="row">
                                                                                <div class="col-12 col-md-6 align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <small>Quem envia</small><br>
                                                                                        {{pedidomensagem.pmsNomeDe}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-6 align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <small>Quem Recebe</small><br>
                                                                                        {{pedidomensagem.pmsNomePara}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-12" style="padding-top: 15px;">
                                                                                    <div class="descricao-img" style="padding: 10px;border: 1px solid #cccccc;height:200px;overflow-y: auto; font-size: 14px;">
                                                                                        <p v-html="pedidomensagem.pmsTexto"></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else><p>Sem Mensagem</p></div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Informações Pedido</h5>
                                                            <h6 class="mb-0">Pedido: {{ PedidosCli.pedId }}</h6> 
                                                            <div class="pedido_dados"><strong>Cliente:</strong> {{ Cliente.cliNomeEmpresa }} - {{ Cliente.cliEmail }} - {{ Cliente.cliWhatsapp }}</div>
                                                            <div class="pedido_dados" v-if="PedidosCli.pedFormaPgto == 'credit_card'"><strong>Pgto:</strong> Cartão Crédito</div>
                                                            <div class="pedido_dados" v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'"><strong>Pgto:</strong> PIX</div>
                                                            <div class="pedido_dados" v-else><strong>Pgto:</strong> {{ PedidosCli.pedFormaPgto }}</div>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Entrega Dia:</strong> {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{ validaHora(PedidosAgendamento[0].pagMinIni) }} e {{ validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{ validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 25px;" color="#f9f9f9">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">itens do Pedido</h5>
                                                            <div v-for="produto in ListaPedidoItem" :key="produto.pdiId" class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="produto.proProdutos.proCampoExtra10 != ''" class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + produto.proProdutos.proCampoExtra10" alt="">
                                                                            <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{ produto.proId }}</p>
                                                                            <p class="texto-mensagem"> Referência: {{ produto.proProdutos.proReferencia }} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ produto.pdiQtdeProduto }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">R$ {{  parseFloat(produto.pdiValorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">R$ {{ parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId" class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="adicional.proProdutosAvulsos.proProdutosAvulsosImagens != null" class="img-fluid border border-rounded mb-3 mb-md-0" style="width:80%;"
                                                                                :src="$imgURL + adicional.proProdutosAvulsos.proProdutosAvulsosImagens[0].paiUrlImagem" alt="">
                                                                            <img v-else class="img-fluid border border-rounded mb-3 mb-md-0" style="width:70%;"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{ adicional.proProdutosAvulsos.praReferencia}}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ adicional.piaQtdeProduto }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">R$ {{  parseFloat(adicional.piaValorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">R$ {{ parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card  style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Cupom Desconto</h5>
                                                                <h6 class="subtitulo">(-) R$ {{ parseFloat(PedidosCli.pedValorCupom).toFixed(2).toString().replace('.',',') }}</h6>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Frete</h5>
                                                                <h6 class="subtitulo">R$ {{ parseFloat(PedidosCli.pedValorFrete).toFixed(2).toString().replace('.',',') }}</h6>
                                                            </div>
                                                             <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h6 class="subtitulo">R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.',',') }}</h6>
                                                            </div>
                                                         </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';

export default {
    name: 'ListaPedidosView',
    components: {
        EasyDataTable,
        moment
    },
    setup() {
        const route = useRoute();
        const id = route.params.idPed;
        return {
            id
        };
    },
    data: () => ({
        tab: null,
        NomeTela: 'Pedidos',
        InfoTela: 'Detalhe Pedido',
        MostraGrid: true,
        Cliente:[],
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 28, cdtNome: "Detelhe Pedido" }],
        PedidosCli: [],
        Pedidos: [],
        TotalPedido:0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova:false,
        ListaPedidoItem:[],
        ListaPedidoItemAdicional:[],
        PedidosMensagem:[],
        PedidosAgendamento:[],
        PedidosEndereco:[],
        Estados:[],
        Cidades:[],
        Bairros:[],
        selectedFile:'',
        urlComprovante: '',
        PedidoComprovante:[],
        Situacoes:[],
    }),
    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        validaHora(value) {
            var _hora = value.toString();
            if (_hora.length == 1)
                _hora = "0" + _hora;
            return _hora;
        },
        calculatotal() {
                var valorAvulsos = 0;
                var valorProdutos = 0;
            

                    if (this.ListaPedidoItem != '') {
                        this.ListaPedidoItem.forEach(item => {
                        valorProdutos += item.pdiValorTotalProduto;
                        });
                    }

                    if (this.ListaPedidoItemAdicional != '') {
                        this.ListaPedidoItemAdicional.forEach(item => {
                        valorAvulsos += item.piaValorTotalProduto;
                        });
                    }

                this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.PedidosCli.pedValorFrete)) - parseFloat(this.PedidosCli.pedValorCupom);
        },
        async PutSituacao(){
          var _situacao = await this.Situacoes.filter(x=> x.pspId == this.PedidosCli.pspId)[0].pspSituacao;
          axios.put(`/Process/PedPedidos/UpdateSituacao/` + this.PedidosCli.pedId + '/' + this.PedidosCli.pspId  + '/' + _situacao)
            .then(response => {
                if (response.status == 200) {
                    this.$mensagemAvisoSucesso("Registro Alterado.")
                    this.EnviaAviso(_situacao);
                }
            });
            //enviar email
        },
        EnviaAviso(_situacao) {
                axios.get(`/Process/CadMensagensPadrao/GetAll`)
                        .then(response => {
                this.templateEmail = response.data.filter(x=> x.mpdId == 3);

                    let _dadosEmail = {
                    nomeDe: 'Cestas Porto Alegre',
                    nomePara:  this.Cliente.cliNomeEmpresa,
                    assunto: 'Andamento Pedido: ' + this.PedidosCli.pedId,
                    destinatario: this.Cliente.cliEmail,
                    emailResposta: 'contato@cestasportoalegre.com',
                    textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa).replace('[NumeroPedido]', this.PedidosCli.pedId).replace('[Status]', _situacao),
                    nomeAnexo: '',
                    anexo: '',
                    tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                    .then(response => {
                        if (response.data == 1) {
                        console.log('email enviado')
                        }
                    });
                });
        },
        async Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/PedPedidos/GetById/` + this.id)
                        .then(response => {
                            this.PedidosCli = response.data;
                            console.log('this.PedidosCli')
                            console.log(this.PedidosCli)

                            axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.cliId)
                                .then(response => {
                                    this.Cliente = response.data;
                                    console.log('this.Cliente')
                                    console.log(this.Cliente)
                                });

                        });
                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;
                    console.log('ListaPedidoItem')
                    console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                    console.log('ListaPedidoItemAdicional')
                    console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosMensagem = response.data;
                    console.log('PedidosMensagem')
                    console.log(this.PedidosMensagem)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosAgendamento = response.data;
                    console.log('PedidosAgendamento')
                    console.log(this.PedidosAgendamento)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                    console.log('PedidosEndereco')
                    console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get(`/Process/CadEstados/GetByPaisId/76`)
                .then(response => {
                    this.Estados = response.data
                    console.log('this.Estados')
                    console.log(this.Estados)
                });

            axios.get(`/Process/CadCidades/GetAll`)
                .then(response => {
                    this.Cidades = response.data
                    console.log('this.Cidades')
                    console.log(this.Cidades)
                });

            axios.get(`/Process/CadBairros/GetAll`)
                .then(response => {
                    this.Bairros = response.data
                    console.log('this.Bairros')
                    console.log(this.Bairros)
                });

                axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    console.log('this.Situacoes')
                    console.log(this.Situacoes)
                });

            axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidoComprovante = response.data.filter(x => x.ppcUrlImagem != '')
                    console.log('this.PedidoComprovante')
                    console.log(this.PedidoComprovante)
                });

            this.calculatotal();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}
</style>
