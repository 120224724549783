<template>
  <div style="background: #ecf0f4;color: #031e23;">
    <div class="login-header box-shadow">
      <div class="container-fluid d-flex justify-content-between align-items-center">
        <div class="brand-logo">
          <router-link to="/">
            <img src="/images/avance-logo-transparente.png" alt="" style="width:257px;height:auto;">
          </router-link>
        </div>
      </div>
    </div>
    <div class="login-wrap d-flex align-items-center flex-wrap justify-content-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-5">
            <div class="login-box bg-white box-shadow border-radius-10">
              <div class="login-title">
                <h2 class="text-center" style="color:#000000;">Login</h2>
              </div>
              <form>
                <div class="input-group custom">
                  <input v-model="login" type="text" class="form-control form-control-lg" placeholder="Login" @keyup.enter.prevent="validaUser()">
                  <div class="input-group-append custom">
                    <span class="input-group-text"><i class="icon-copy dw dw-user1"></i></span>
                  </div>
                </div>
                <div class="input-group custom">
                  <input v-model="senha" type="password" class="form-control form-control-lg" placeholder="**********" @keyup.enter.prevent="validaUser()">
                  <div class="input-group-append custom">
                    <span class="input-group-text"><i class="dw dw-padlock1"></i></span>
                  </div>
                </div>
                <div class="row pb-30">
                  <div class="col-6">
                  </div>
                  <div class="col-6">
                    <div class="forgot-password"><a href="#" @click="dialog = true;">Esqueci Senha</a></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="input-group mb-0">
                      <a :loading="isLoading" class="btn btn-primary btn-lg btn-block" href="#" @click="validaUser()">Acessar</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 col-lg-7">
            <img src="/images/login-page-img.png" alt="" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent width="600">
        <v-card>
          <v-card-title>
            <span class="text-h5" style="padding-left: 10px;">Lembrar Senha</span>
          </v-card-title>
          <v-card-text>
            <input v-model="resetEmail" type="text" class="form-control form-control-lg" placeholder="Digite seu E-mail">
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false"
              style="padding-right: 20px;">Fechar</v-btn>
            <a class="btn btn-primary btn-lg btn-block" style="width: 100px;padding-right: 10px;" href="#" @click="EnviaReset();dialog=false">Enviar</a>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>



  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { SHA256 } from 'crypto-js';


export default {
  name: 'LoginView',
  components: {
    moment,
  },
  data: () => ({
    dialog: false,
    login: '',
    senha: '',
    User: {},
    resetEmail:'',
    UserReset:[],
    templateEmail:[],
    TelasUser:[],
  }),
  methods: {
    validaUser() {
      if (this.login == '' || this.login == null) {
        this.$mensagemErro("Login obrigatório.")
      }
      else if (this.senha == '' || this.senha == null) {
        this.$mensagemErro("Senha obrigatória.")
      }
      else {
        const param1 = this.login;
        const param2 = this.senha;

        axios.get(`/Process/UsrCadastroUsuarios/GetByPassword/${param1}/${param2}`)
          .then(response => {
              this.User = response.data; 
              const param3 = this.User.cusId;
              axios.get(`/Process/UsrControlePermissoes/GetAllByUserId/${param3}`)
                  .then(response => {
                      this.TelasUser = response.data;
                      let _dadoUser = {
                        nome: this.User.cusNome,
                        urlimage: this.User.cusUrlImagem,
                        menu: this.TelasUser
                      }
                      this.$Cookies.set('_vusav', this.$CookieUsuario, this.$CookiesDefaults);
                      localStorage.setItem('_userav', JSON.stringify(_dadoUser));
                      this.$router.push('/Home')
                  });
          });
      }
    },
    EnviaReset() {
      if (this.resetEmail == '' || this.resetEmail == null) {
        this.$mensagemErro("Digite e-mail cadastrado.")
      }
      else {

        axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x=> x.mpdId == 1);

                    const param1 = this.resetEmail;
                    axios.get(`/Process/UsrCadastroUsuarios/GetByEmail/${param1}`).then(response => {
                      this.UserReset = response.data;  
                      if (this.UserReset!='') {
                          const encrypted = SHA256(this.UserReset.cusEmail).toString();
                          let _dadosEmail = {
                                                nomeDe: 'Sistema Sgcad',
                                                nomePara: this.UserReset.cusNome,
                                                assunto: 'Reset de Senha',
                                                destinatario: this.UserReset.cusEmail,
                                                emailResposta: 'contato@formulariodosite.com.br',
                                                textoEmail: this.templateEmail[0].mpdTexto.replace('[SenhaReset]','http://localhost:8080/ResetSenha/'+ this.UserReset.cusId + '/' + encrypted),
                                                nomeAnexo: '',
                                                anexo: '',
                                                tipoAnexo: ''
                                              }
                                    axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail)
                                    .then(response => {
                                        if (response.data == 1){
                                          this.$mensagemSucesso("Link para reset de senha enviado para o seu e-mail! ");
                                          this.resetEmail = "";
                                        }
                                      });
                    }
                    else{
                      this.$mensagemErro("E-mail não cadastrado")
                    }
              });
        });
      }
    }
  },
  created() {
   
  }
}
</script>

<style>

.v-card-actions {
  align-items: center;
  display: flex;
  flex: none;
  min-height: 52px;
  padding: 0.5rem;
  padding-right: 20px !important;
}
</style>
