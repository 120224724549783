<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ConfigPagamento.pfcId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ConfigPagamento.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ConfigPagamento.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="ConfigPagamento.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(ConfigPagamento.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="ConfigPagamento.cfpId" label="Forma Pagamento" :items="CadFormasPagamento"
                                                item-title="cfpNome" item-value="cfpId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="ConfigPagamento.pfcNomefront"
                                                label="Nome Aparece Cliente" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="ConfigPagamento.pfcStatus" label="Ativo" :items="StatusVisivel"
                                                item-title="nome" item-value="pfcStatus" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="ConfigPagamento.pfcOrdem"
                                                label="Ordem" variant="underlined" required></v-text-field>
                                        </v-col>
 
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="ConfigPagamento.pfcToken" label="Token"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="ConfigPagamento.pfcSenha"
                                                label="Senha" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="ConfigPagamento.pfcEmailCadastro"
                                                label="E-mail Cadastrado" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="ConfigPagamento.pfcEmailRecebeAviso"
                                                label="E-mail Recebe Aviso" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <small style="padding-bottom:5px;"><strong>Dados</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="ConfigPagamento.pfcDados" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <v-text-field v-model="ConfigPagamento.pfcTituloTextoFinalizado"
                                                label="Titulo Pedido Finalizado" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <small style="padding-bottom:5px;"><strong>Texto Tela Pedido Finalizado</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="ConfigPagamento.pfcTextoFinalizado" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input v-model="selectedFilePgto" label="Imagem Pgto 300 x 500px" variant="underlined"
                                                prepend-icon="mdi-image-outline" accept="image/*"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ConfigPagamento.pfcUrlImgPgto !== '' && ConfigPagamento.pfcUrlImgPgto !== null">
                                            <img :src="$imgURL + ConfigPagamento.pfcUrlImgPgto" style="width: 100px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                                <v-icon @click="DeleteImagemPgto()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input v-model="selectedFileFundo" label="Imagem Fundo Finalizado 1900 x 1000px" variant="underlined"
                                                prepend-icon="mdi-image-outline" accept="image/*"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="ConfigPagamento.pfcUrlImgFundo !== '' && ConfigPagamento.pfcUrlImgFundo !== null">
                                            <img :src="$imgURL + ConfigPagamento.pfcUrlImgFundo" style="width: 100px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                                <v-icon @click="DeleteImagemFundo()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ConfigPagamento.pfcId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListConfigPagamento)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-pfcStatus="item">
                            <span>{{ item.pfcStatus ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'
import { useRoute } from 'vue-router';

export default {
    name: 'ConConfigFormasPagamentoView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Config Formas Pagamento',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 27, cdtNome: "Config Formas Pagamento" }],
        //dados
        ConfigPagamento: {
            pfcId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            cfpId:'',
            pfcNomefront: '',
            pfcStatus: '',
            pfcOrdem: '',
            pfcDados: '',
            pfcToken: '',
            pfcSenha: '',
            pfcEmailCadastro: '',
            pfcEmailRecebeAviso: '',
            pfcTituloTextoFinalizado: '',
            pfcTextoFinalizado: '',
            pfcUrlImgPgto: '',
            pfcUrlImgFundo: '',
        },
        ListConfigPagamento: [],
        StatusVisivel: [{ nome: "Sim", pfcStatus: true }, { nome: "Não", pfcStatus: false }],
        CadFormasPagamento:[],
        //dados
        selectedFilePgto:'',
        selectedFileFundo:'',
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                pfcOrdem: parseInt(item.pfcOrdem),
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListConfigPagamento = this.ListConfigPagamentoGeral.filter(x=> x.idiId == this.ConfigPagamento.idiId);
        },
        Limpar() {
            this.ConfigPagamento.pfcId=0;
            this.ConfigPagamento.grtId=0;
            this.ConfigPagamento.cdtId=0;
            this.ConfigPagamento.cfpId="";
            this.ConfigPagamento.pfcNomefront="";
            this.ConfigPagamento.pfcStatus="";
            this.ConfigPagamento.pfcOrdem="";
            this.ConfigPagamento.pfcDados="";
            this.ConfigPagamento.pfcToken="";
            this.ConfigPagamento.pfcSenha="";
            this.ConfigPagamento.pfcEmailCadastro="";
            this.ConfigPagamento.pfcEmailRecebeAviso="";
            this.ConfigPagamento.pfcTituloTextoFinalizado="";
            this.ConfigPagamento.pfcTextoFinalizado="";
            this.ConfigPagamento.pfcUrlImgPgto="";
            this.ConfigPagamento.pfcUrlImgFundo="";
            this.selectedFilePgto = "";
            this.selectedFileFundo = "";
            this.tab = 'dados';
            this.Get();
        },
        DeleteImagemPgto(){
            this.ConfigPagamento.pfcUrlImgPgto = "";
        },
        DeleteImagemFundo(){
            this.ConfigPagamento.pfcUrlImgFundo = "";
        },
        async Add() {
               if (this.ConfigPagamento.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.ConfigPagamento.cfpId == '') {
                    this.$mensagemAviso("Campo Forma Pagamento Obrigatória.")
                }
                else if (this.ConfigPagamento.pfcNomefront === '') {
                    this.$mensagemAviso("Campo Nome Front Obrigatório.")
                }
                else if (this.ConfigPagamento.pfcStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else if (this.ConfigPagamento.pfcStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else {
                    if (this.ConfigPagamento.pfcStatus === "" || this.ConfigPagamento.pfcStatus === null) {
                        this.ConfigPagamento.pfcStatus = false;
                    }

                    if (this.ConfigPagamento.pfcOrdem === "" || this.ConfigPagamento.pfcOrdem === null) {
                        this.ConfigPagamento.pfcOrdem = 0;
                    }
                    else {
                        this.ConfigPagamento.pfcOrdem = parseInt(this.ConfigPagamento.pfcOrdem);
                    }
               
                    if (this.selectedFilePgto != '' && this.selectedFilePgto != null) {
                        const uploadSuccess = await this.UploadImg(this.selectedFilePgto[0]);
                        if (uploadSuccess.status == 200) {
                            this.ConfigPagamento.pfcUrlImgPgto = uploadSuccess.data.nameFile;
                        }
                    }

                    if (this.selectedFileFundo != '' && this.selectedFileFundo != null) {
                        const uploadSuccess = await this.UploadImg(this.selectedFileFundo[0]);
                        if (uploadSuccess.status == 200) {
                            this.ConfigPagamento.pfcUrlImgFundo = uploadSuccess.data.nameFile;
                        }
                    }

                    axios.post(`/Process/ConConfigFormasPagamento/Add`, this.ConfigPagamento)
                        .then(response => {
                            if (response.data > 0) {
                                   this.$mensagemAvisoSucesso("Registro Incluído.")
                                   this.Get();
                                   this.Limpar();
                                   this.tab = 'dados';                        
                            }
                        });
            }
        },
        async Put() {
            if (this.ConfigPagamento.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.ConfigPagamento.cfpId == '') {
                    this.$mensagemAviso("Campo Forma Pagamento Obrigatória.")
                }
                else if (this.ConfigPagamento.pfcNomefront === '') {
                    this.$mensagemAviso("Campo Nome Front Obrigatório.")
                }
                else if (this.ConfigPagamento.pfcStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else if (this.ConfigPagamento.pfcStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
            else {
                if (this.ConfigPagamento.pfcStatus === "" || this.ConfigPagamento.pfcStatus === null) {
                        this.ConfigPagamento.pfcStatus = false;
                    }

                    if (this.ConfigPagamento.pfcOrdem === "" || this.ConfigPagamento.pfcOrdem === null) {
                        this.ConfigPagamento.pfcOrdem = 0;
                    }
                    else {
                        this.ConfigPagamento.pfcOrdem = parseInt(this.ConfigPagamento.pfcOrdem);
                    }

                    if (this.selectedFilePgto != '' && this.selectedFilePgto != null) {
                        const uploadSuccess = await this.UploadImg(this.selectedFilePgto[0]);
                        if (uploadSuccess.status == 200) {
                            this.ConfigPagamento.pfcUrlImgPgto = uploadSuccess.data.nameFile;
                        }
                    }
                    else{
                        if (this.ConfigPagamento.pfcUrlImgPgto != "") {
                           this.ConfigPagamento.pfcUrlImgPgto = "0";
                        }
                    }

                    if (this.selectedFileFundo != '' && this.selectedFileFundo != null) {
                        const uploadSuccess = await this.UploadImg(this.selectedFileFundo[0]);
                        if (uploadSuccess.status == 200) {
                            this.ConfigPagamento.pfcUrlImgFundo = uploadSuccess.data.nameFile;
                        }
                    }
                    else{
                        if (this.ConfigPagamento.pfcUrlImgFundo != "") {
                          this.ConfigPagamento.pfcUrlImgFundo = "0";
                        }
                    }
             
                    axios.put(`/Process/ConConfigFormasPagamento/Update`, this.ConfigPagamento)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pfcId;
                axios.delete(`/Process/ConConfigFormasPagamento/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item          
            this.ConfigPagamento = item;
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "pfcId", sortable: true, });
            this.headers.push({ text: "Nome Front", value: "pfcNomefront", sortable: true, });
            this.headers.push({ text: "Status", value: "pfcStatus", sortable: true, });
            this.headers.push({ text: "Ordem", value: "pfcOrdem", sortable: true, }); 
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.ConfigPagamento.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/ConConfigFormasPagamento/GetAll`)
                        .then(response => {
                            this.ListConfigPagamentoGeral = response.data; 
                            if (this.ConfigPagamento.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                        axios.get(`/Process/CadFormasPagamento/GetAll`)
                        .then(response => {
                            this.CadFormasPagamento = response.data.filter(x=> x.cfpVisivel);
                        });
                    this.montaGrid();
                    this.ConfigPagamento.cdtId = 27;
                    this.ConfigPagamento.grtId = 6;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
