<template>
    <div>
      <div class="pd-ltr-20" style="padding-top: 100px;">
      <div class="footer-wrap pd-20 mb-20 card-box">
          Desenvolvido por <a href="https://avancedigital.com.br" target="_blank"><img src="/images/avance-logo-transparente.png" alt="" style="width:100px;height:auto;"></a>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: 'FooterPage',
  components: {

  },
  data: () => ({

  }),
  methods: {
  },
  created () {
  }
}
</script>

<style scoped>
</style>
