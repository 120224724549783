<template>
 <v-app>
    <v-main>
      <Loader v-if="$loading.value"></Loader>
      <v-layout column>
        <v-flex v-if="checkCabecalho()" style="background-color: #364f66;">
          <Header />
        </v-flex>
        <v-layout row wrap>
          <div class="main-container" :class="{ 'expanded': sidebarClosed }" style="background-color: #ecf0f4;">
            <router-view :key="$route.fullPath" />
            <FooterPage />
          </div>
        </v-layout>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'
import Loader from './components/PageLoader.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage,
    Loader
  },
  data: () => ({
    drawer: true,
    rail: true,
    sidebarClosed: false,
  }),
  methods: {
    checkCabecalho() {
      //console.log(this.$route.fullPath)
      if (this.$route.fullPath === '/' || this.$route.fullPath.includes('ResetSenha')) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
  },
}
</script>
<style>
@import "@/vendors/styles/core.css";
@import "@/vendors/styles/icon-font.min.css";
@import "@/vendors/styles/style.css";

@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }

  .bordasEsp {
    padding-left: 15px;padding-right: 10px;padding-bottom: 10px;padding-top: 30px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }

  .bordasEsp {
    padding-left: 20px;padding-right: 10px;padding-bottom: 10px;padding-top: 30px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important;
  }

  .bordasEsp {
    padding-left: 40px;padding-right: 20px;padding-bottom: 10px;padding-top: 30px;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important;
  }

  .bordasEsp {
    padding-left: 40px;padding-right: 20px;padding-bottom: 10px;padding-top: 30px;
  }
}


.main-container {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.v-label {
    align-items: center;
    display: inline-flex;
    font-size: 14px !important;
    letter-spacing: 0.009375em;
    min-width: 0;
    opacity: var(--v-medium-emphasis-opacity);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* ckeditor */
.ck.ck-editor__editable_inline {
  border:1px solid #cccccc !important;
  min-height: 100px;
}

/* ckeditor */


/* customiza datatables */

.customize-table {
  --easy-table-border: 1px solid #364f66;
  --easy-table-row-border: 1px solid #364f66;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 30px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #364f66;

  --easy-table-header-item-padding: 10px 5px;

  --easy-table-body-even-row-font-color: #000;
  --easy-table-body-even-row-background-color: #ecf0f4;

  --easy-table-body-row-font-color: #000000;
  /*--easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #2d3a4f;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
  
   <!--
   <template #item-player="{ player, avator, page }">
      <div class="player-wrapper">
        <img class="avator" :src="avator" alt="" />
        <a target="_blank" :href="page">{{ player }}</a>
      </div>
    </template>
    <template #item-team="{ teamName, teamUrl }">
      <a target="_blank" :href="teamUrl">{{ teamName }}</a>
    </template>
    <template #item-operation="item">
      <div class="operation-wrapper">
          <img
          src="./images/delete.png"
          class="operation-icon"
          @click="deleteItem(item)"
          />
          <img
          src="./images/edit.png"
          class="operation-icon"
          @click="editItem(item)"
          />
      </div>
      </template>

                  /*{ text: "Id", value: "grtId",fixed: true, width: 40 }, -- congela coluna*/
             /*{ text: "Id", value: "grtId",sortable: true, width: 200 }, -- ordenação coluna

                     // formattedDate (value) {
        //    return this.$moment(value).format('DD/MM/YYYY')
        // }

        //import moment from 'moment'
    -->
  
  */
}

/* customiza datatables */
</style>
