<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Grupos de telas institucional</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="GrupoTela.gtiId" label="Id" variant="underlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="GrupoTela.gtiNome" :rules="nameRules" :counter="200"
                                        label="Nome Grupo" variant="underlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-text-field v-model="GrupoTela.gtiOrdem" label="Ordem" type="number"
                                        variant="underlined"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select v-model="GrupoTela.gtiVisivel" label="Visível" :items="Status"
                                        item-title="nome" item-value="gtiVisivel" variant="underlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="GrupoTela.gtiIcon" label="Icone"
                                        variant="underlined"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="GrupoTela.gtiId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(GrupoTelas)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-gtiVisivel="item">
                            <span>{{ item.gtiVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-gtiOrdem="item">
                            <span>{{ parseInt(item.gtiOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'GrupoTelasInstitucionalView',
    components: {
        EasyDataTable
    },
    data: () => ({
        GrupoTela: {
            gtiId: 0,
            gtiNome: '',
            gtiOrdem: '',
            gtiVisivel: '',
            gtiIcon: '',
        },
        GrupoTelas: [],
        selectedVisivel: null,
        Status: [{ nome: "Sim", gtiVisivel: true }, { nome: "Não", gtiVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "gtiId", sortable: true, },
            { text: "Nome", value: "gtiNome", sortable: true, },
            { text: "Ordem", value: "gtiOrdem", sortable: true, },
            { text: "Visivel", value: "gtiVisivel", sortable: true, },
            { text: "Icone", value: "gtiIcon" },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                gtiOrdem: parseInt(item.gtiOrdem),
            };
            });
        },
        Limpar() {
            this.GrupoTela.gtiId = 0;
            this.GrupoTela.gtiNome = '';
            this.GrupoTela.gtiOrdem = '';
            this.GrupoTela.gtiVisivel = '';
            this.GrupoTela.gtiIcon = '';
            this.selectedVisivel = '';
            this.Get();
        },
        Add() {
            if (this.GrupoTela.gtiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.GrupoTela.gtiOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.GrupoTela.gtiVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else {
                this.GrupoTela.gtiOrdem = parseInt(this.GrupoTela.gtiOrdem)
                axios.post(`/Process/AdmGrupoTelasInst/Add`, this.GrupoTela)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.GrupoTela.gtiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.GrupoTela.gtiOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.GrupoTela.gtiVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else {
                this.GrupoTela.gtiOrdem = parseInt(this.GrupoTela.gtiOrdem)
                axios.put(`/Process/AdmGrupoTelasInst/Update`, this.GrupoTela)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.GrupoTela = item;
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.gtiId;
                axios.delete(`/Process/AdmGrupoTelasInst/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/AdmGrupoTelasInst/GetAll`)
                .then(response => {
                    this.GrupoTelas = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
