<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <v-tab value="Imagens" v-if="ProdutosAvulsos.praId != 0" class="tabinfo">Imagens</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ProdutosAvulsos.praId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ProdutosAvulsos.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="ProdutosAvulsos.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="ProdutosAvulsos.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(ProdutosAvulsos.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <v-text-field v-model="ProdutosAvulsos.praNomeProduto"
                                                label="Nome Produto" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="ProdutosAvulsos.praReferencia"
                                                label="Referência" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="ProdutosAvulsos.praVisivel" label="Visível" :items="StatusVisivel"
                                                item-title="nome" item-value="praVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="ProdutosAvulsos.praOrdem" label="Ordem" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display:none">
                                            <v-select v-model="ProdutosAvulsos.praDestaque" label="Destaque" :items="StatusDestaque"
                                                item-title="nome" item-value="praDestaque" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="ProdutosAvulsos.praTempoProducao"
                                                label="Tempo Produção (min)" variant="underlined" required type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="ProdutosAvulsos.praValorDe" type="number"
                                            @change="ProdutosAvulsos.praValorDe != null || ProdutosAvulsos.praValorDe != '' ? (ProdutosAvulsos.praValorDe = parseFloat(ProdutosAvulsos.praValorDe,10)) : (ProdutosAvulsos.praValorDe = 0.0)"
                                                label="Valor De" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="ProdutosAvulsos.praValorPor" type="number"
                                            @change="ProdutosAvulsos.praValorPor != null || ProdutosAvulsos.praValorPor != '' ? (ProdutosAvulsos.praValorPor = parseFloat(ProdutosAvulsos.praValorPor,10)) : (ProdutosAvulsos.praValorPor = 0.0)"
                                                label="Valor Por" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" style="display:none">
                                            <small style="padding-bottom:5px;"><strong>Descrição</strong></small>
                                            <ckeditor :editor="editor" @ready="onReady" v-model="ProdutosAvulsos.praTexto1" :config="$ckconfig"></ckeditor>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praEtiqueta"
                                                label="Etiqueta" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praEtiquetaCorFonte" type="color"
                                                label="Cor Fonte" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praEtiquetaCorFundo" type="color"
                                                label="Cor Fundo" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praPeso" type="number"
                                            @change="ProdutosAvulsos.praPeso != null || ProdutosAvulsos.praPeso != '' ? (ProdutosAvulsos.praPeso = parseFloat(ProdutosAvulsos.praPeso,10)) : (ProdutosAvulsos.praPeso = 0.0)"
                                                label="Peso (gramas)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praAltura" type="number"
                                            @change="ProdutosAvulsos.praAltura != null || ProdutosAvulsos.praAltura != '' ? (ProdutosAvulsos.praAltura = parseFloat(ProdutosAvulsos.praAltura,10)) : (ProdutosAvulsos.praAltura = 0.0)"
                                                label="Altura (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praLargura" type="number"
                                            @change="ProdutosAvulsos.praLargura != null || ProdutosAvulsos.praLargura != '' ? (ProdutosAvulsos.praLargura = parseFloat(ProdutosAvulsos.praLargura,10)) : (ProdutosAvulsos.praLargura = 0.0)"
                                                label="Largura (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praProfundidade" type="number"
                                            @change="ProdutosAvulsos.praProfundidade != null || ProdutosAvulsos.praProfundidade != '' ? (ProdutosAvulsos.praProfundidade = parseFloat(ProdutosAvulsos.praProfundidade,10)) : (ProdutosAvulsos.praProfundidade = 0.0)"
                                                label="Profundidade (cm)" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praCampoExtra1"
                                                label="Campo Extra 1" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praCampoExtra2"
                                                label="Campo Extra 2" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praCampoExtra3"
                                                label="Campo Extra 3" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display:none">
                                            <v-text-field v-model="ProdutosAvulsos.praCampoExtra4"
                                                label="Campo Extra 4" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <!--Imagens-->
                        <v-window-item value="Imagens" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Upload de até 1 imagem com dimensão : Largura: 500px - Altura: 500px</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="3">
                                         <v-file-input v-model="selectedFile" multiple label="Imagens" variant="underlined"
                                            prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, image/gif"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddImagens(ProdutosAvulsos.praId)">
                                            Carregar Imagens
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row v-for="imgs in ProdutosAvulsosImgs" :key="imgs.PaiId">
                                            <v-col cols="12" md="4" v-show="false">
                                                <v-text-field v-model="imgs.paiId" label="Id" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-col cols="12" md="12">
                                                    <v-img :src="$imgURL + imgs.paiUrlImagem" variant="underlined"></v-img>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" md="8" style="display:none">
                                                <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.paiNome" label="Nome" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.paiOrdem" label="Ordem" type="number" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-select v-model="imgs.paiVisivel" label="Visível" :items="StatusProdAvImgVisivel"
                                                        item-title="nome" item-value="paiVisivel" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="imgs.paiAlt" label="Alt" variant="underlined"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <small style="padding-bottom:5px;"><strong>Texto</strong></small>
                                                    <ckeditor :editor="editor" @ready="onReady" v-model="imgs.paiResumo" :config="$ckconfig"></ckeditor>
                                                </v-col>
                                            </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn icon @click="SaveImages(imgs)" style="margin-right: 10px;display:none" variant="text">
                                                    <v-icon>mdi-content-save-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                </v-btn>

                                                <v-btn icon @click.prevent="DeleteImages(imgs)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ProdutosAvulsos.praId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ListProdutosAvulsos)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-praVisivel="item">
                            <span>{{ item.praVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-praValorDe="item">
                            {{ item.praValorDe !== null ? formatarValor(item.praValorDe) : 0 }}
                        </template>
                        <template #item-praValorPor="item">
                            {{ item.praValorPor !== null ? formatarValor(item.praValorPor) : 0 }}
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'
import { useRoute } from 'vue-router';

export default {
    name: 'ProProdutosAvulsosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Produtos Avulsos',
        InfoTela:'Lista de produtos',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 16, cdtNome: "Produtos Avulsos" }],
        //dados
        ProdutosAvulsos: {
            praId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            praNomeProduto: '',
            praReferencia: '',
            praTempoProducao: '',
            praValorDe: '',
            praValorPor: '',
            praVisivel: '',
            praOrdem: '',
            praDestaque: '',
            praTexto1: '',
            praEtiqueta: '',
            praEtiquetaCorFonte: '',
            praEtiquetaCorFundo: '',
            praPeso: '',
            praAltura: '',
            praLargura: '',
            praProfundidade: '',
            praCampoExtra1: '',
            praCampoExtra2: '',
            praCampoExtra3: '',
            praCampoExtra4: '',
        },
        ListProdutosAvulsos: [],
        StatusVisivel: [{ nome: "Sim", praVisivel: true }, { nome: "Não", praVisivel: false }],
        StatusDestaque: [{ nome: "Sim", praDestaque: true }, { nome: "Não", praDestaque: false }],
        //dados
        //imagens
        ProdutosAvulsosImgs:[],
        ListaProdAvImgs:[],
        ImagemProdutosAvulsos:{},
        selectedFile: null,
        StatusProdAvImgVisivel: [{ nome: "Sim", paiVisivel: true }, { nome: "Não", paiVisivel: false }],
        //imagens
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                praOrdem: parseInt(item.praOrdem),
            };
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListProdutosAvulsos = this.ListProdutosAvulsosGeral.filter(x=> x.idiId == this.ProdutosAvulsos.idiId);
        },
        Limpar() {
            this.ProdutosAvulsos.praId=0;
            this.ProdutosAvulsos.grtId=0;
            this.ProdutosAvulsos.cdtId=0;
            this.ProdutosAvulsos.praNomeProduto="";
            this.ProdutosAvulsos.praReferencia="";
            this.ProdutosAvulsos.praTempoProducao="";
            this.ProdutosAvulsos.praValorDe="";
            this.ProdutosAvulsos.praValorPor="";
            this.ProdutosAvulsos.praVisivel="";
            this.ProdutosAvulsos.praOrdem="";
            this.ProdutosAvulsos.praDestaque="";
            this.ProdutosAvulsos.praTexto1="";
            this.ProdutosAvulsos.praEtiqueta="";
            this.ProdutosAvulsos.praEtiquetaCorFonte="";
            this.ProdutosAvulsos.praEtiquetaCorFundo="";
            this.ProdutosAvulsos.praPeso="";
            this.ProdutosAvulsos.praAltura="";
            this.ProdutosAvulsos.praLargura="";
            this.ProdutosAvulsos.praProfundidade="";
            this.ProdutosAvulsos.praCampoExtra1="";
            this.ProdutosAvulsos.praCampoExtra2="";
            this.ProdutosAvulsos.praCampoExtra3="";
            this.ProdutosAvulsos.praCampoExtra4="";
            this.ListaProdAvImgs = [];
            this.tab = 'dados';
            this.Get();
        },
        async AddImagens(value){      
            if (this.selectedFile != '' && this.selectedFile != null) {
                var qtdebase = this.ProdutosAvulsosImgs.filter(x=> x.proProdutosAvulsos.praId == value).length;
                if ((this.selectedFile.length + qtdebase) <= 1){
                    let selected2Promises = this.selectedFile.map(async (element) => {
                            const uploadSuccess = await this.UploadImg(element);
                            if (uploadSuccess.status == 200) {
                                this.ImagemProdutosAvulsos.paiUrlImagem = uploadSuccess.data.nameFile;
                                this.ImagemProdutosAvulsos.paiVisivel = true;
                                this.ImagemProdutosAvulsos.paiResumo = "";
                                this.ImagemProdutosAvulsos.ProProdutosAvulsos = { PraId: value };
                                this.ImagemProdutosAvulsos.praId = this.ProdutosAvulsos.praId
                                return axios.post(`/Process/ProProdutosAvulsosImagens/Add`, this.ImagemProdutosAvulsos);
                            } else {
                                this.$mensagemErro("Não foi possível carregar a imagem.");
                                return null;
                            }
                    });

                    Promise.all(selected2Promises)
                        .then((responses) => {
                            this.$mensagemAvisoSucesso("Registro incluído.");
                            this.CarregaImagens();
                            this.selectedFile = null;
                        })
                        .catch((error) => {
                            this.$mensagemAviso("Alguma imagem pode ter dado erro na carga." + error);
                        });
                        
                    }
                    else{
                        this.$mensagemAviso("Quantidade máxima de imagens excedida.")
                    }
                }
                else{
                    this.$mensagemAviso("Selecione uma imagem.")
                }
        },
        SaveImages(value){
            var _imginfo = value;
            if (_imginfo.paiOrdem === "" || _imginfo.paiOrdem === null) {
                _imginfo.paiOrdem = 0;
            }
            else {
                _imginfo.paiOrdem = parseInt(_imginfo.paiOrdem);
            }
            
            axios.put(`/Process/ProProdutosAvulsosImagens/Update`, _imginfo)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaImagens();
                        }
                    });
        },
        DeleteImages(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.paiId;
                axios.delete(`/Process/ProProdutosAvulsosImagens/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaImagens();
                    }
                });
            }
            });
        },
        Add() {
               if (this.ProdutosAvulsos.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.ProdutosAvulsos.praNomeProduto == '') {
                    this.$mensagemAviso("Campo Nome Produto Obrigatório.")
                }
                else if (this.ProdutosAvulsos.praVisivel === '') {
                    this.$mensagemAviso("Campo Visível Obrigatório.")
                }
                else if (this.ProdutosAvulsos.praOrdem === '') {
                    this.$mensagemAviso("Campo Ordem Obrigatório.")
                }
                else {
                    if (this.ProdutosAvulsos.praVisivel === "" || this.ProdutosAvulsos.praVisivel === null) {
                        this.ProdutosAvulsos.praVisivel = false;
                    }
                    if (this.ProdutosAvulsos.praDestaque === "" || this.ProdutosAvulsos.praDestaque === null) {
                        this.ProdutosAvulsos.praDestaque = false;
                    }
                    if (this.ProdutosAvulsos.praOrdem === "" || this.ProdutosAvulsos.praOrdem === null) {
                        this.ProdutosAvulsos.praOrdem = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praOrdem = parseInt(this.ProdutosAvulsos.praOrdem);
                    }
                    if (this.ProdutosAvulsos.praTempoProducao === "" || this.ProdutosAvulsos.praTempoProducao === null) {
                        this.ProdutosAvulsos.praTempoProducao = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praTempoProducao = parseInt(this.ProdutosAvulsos.praTempoProducao);
                    }
                    if (this.ProdutosAvulsos.praPeso === "" || this.ProdutosAvulsos.praPeso === null) {
                        this.ProdutosAvulsos.praPeso = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praPeso = parseFloat(this.ProdutosAvulsos.praPeso);
                    }
                    if (this.ProdutosAvulsos.praAltura === "" || this.ProdutosAvulsos.praAltura === null) {
                        this.ProdutosAvulsos.praAltura = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praAltura = parseFloat(this.ProdutosAvulsos.praAltura);
                    }
                    if (this.ProdutosAvulsos.praLargura === "" || this.ProdutosAvulsos.praLargura === null) {
                        this.ProdutosAvulsos.praLargura = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praLargura = parseFloat(this.ProdutosAvulsos.praLargura);
                    }
                    if (this.ProdutosAvulsos.praProfundidade === "" || this.ProdutosAvulsos.praProfundidade === null) {
                        this.ProdutosAvulsos.praProfundidade = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praProfundidade = parseFloat(this.ProdutosAvulsos.praProfundidade);
                    }
                    if (this.ProdutosAvulsos.praValorDe === "" || this.ProdutosAvulsos.praValorDe === null) {
                        this.ProdutosAvulsos.praValorDe = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praValorDe = parseFloat(this.ProdutosAvulsos.praValorDe);
                    }
                    if (this.ProdutosAvulsos.praValorPor === "" || this.ProdutosAvulsos.praValorPor === null) {
                        this.ProdutosAvulsos.praValorPor = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praValorPor = parseFloat(this.ProdutosAvulsos.praValorPor);
                    }


                    this.ProdutosAvulsos.admGrupoTelas = { GrtId: this.ProdutosAvulsos.grtId }
                    this.ProdutosAvulsos.admCadastroTelas = { CdtId: this.ProdutosAvulsos.cdtId }
                    this.ProdutosAvulsos.cadIdiomas = { IdiId: this.ProdutosAvulsos.idiId }
                 
                    axios.post(`/Process/ProProdutosAvulsos/Add`, this.ProdutosAvulsos)
                        .then(response => {
                            if (response.data > 0) {
                                    this.ProdutosAvulsos.praId = response.data;
                                    this.Get();
                                    this.EditGrid(this.ProdutosAvulsos);
                                    this.$mensagemAvisoSucesso("Registro Incluído. aba imagens liberada.")

                                   //this.$mensagemAvisoSucesso("Registro Incluído.")
                                   //this.Get();
                                   //this.Limpar();
                                  // this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.ProdutosAvulsos.idiId == '') {
                this.$mensagemAviso("Campo Idioma Obrigatório.")
            }
            else if (this.ProdutosAvulsos.praNomeProduto == '') {
                this.$mensagemAviso("Campo Nome Produto Obrigatório.")
            }
            else if (this.ProdutosAvulsos.praVisivel === '') {
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else if (this.ProdutosAvulsos.praOrdem === '') {
                this.$mensagemAviso("Campo Ordem Obrigatório.")
            }
            else {
                if (this.ProdutosAvulsos.praVisivel === "" || this.ProdutosAvulsos.praVisivel === null) {
                    this.ProdutosAvulsos.praVisivel = false;
                }
                if (this.ProdutosAvulsos.praDestaque === "" || this.ProdutosAvulsos.praDestaque === null) {
                    this.ProdutosAvulsos.praDestaque = false;
                }
                if (this.ProdutosAvulsos.praOrdem === "" || this.ProdutosAvulsos.praOrdem === null) {
                    this.ProdutosAvulsos.praOrdem = 0;
                }
                else {
                    this.ProdutosAvulsos.praOrdem = parseInt(this.ProdutosAvulsos.praOrdem);
                }
                if (this.ProdutosAvulsos.praTempoProducao === "" || this.ProdutosAvulsos.praTempoProducao === null) {
                        this.ProdutosAvulsos.praTempoProducao = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praTempoProducao = parseInt(this.ProdutosAvulsos.praTempoProducao);
                    }
                    if (this.ProdutosAvulsos.praPeso === "" || this.ProdutosAvulsos.praPeso === null) {
                        this.ProdutosAvulsos.praPeso = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praPeso = parseFloat(this.ProdutosAvulsos.praPeso);
                    }
                    if (this.ProdutosAvulsos.praAltura === "" || this.ProdutosAvulsos.praAltura === null) {
                        this.ProdutosAvulsos.praAltura = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praAltura = parseFloat(this.ProdutosAvulsos.praAltura);
                    }
                    if (this.ProdutosAvulsos.praLargura === "" || this.ProdutosAvulsos.praLargura === null) {
                        this.ProdutosAvulsos.praLargura = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praLargura = parseFloat(this.ProdutosAvulsos.praLargura);
                    }
                    if (this.ProdutosAvulsos.praProfundidade === "" || this.ProdutosAvulsos.praProfundidade === null) {
                        this.ProdutosAvulsos.praProfundidade = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praProfundidade = parseFloat(this.ProdutosAvulsos.praProfundidade);
                    }
                    if (this.ProdutosAvulsos.praValorDe === "" || this.ProdutosAvulsos.praValorDe === null) {
                        this.ProdutosAvulsos.praValorDe = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praValorDe = parseFloat(this.ProdutosAvulsos.praValorDe);
                    }
                    if (this.ProdutosAvulsos.praValorPor === "" || this.ProdutosAvulsos.praValorPor === null) {
                        this.ProdutosAvulsos.praValorPor = 0;
                    }
                    else {
                        this.ProdutosAvulsos.praValorPor = parseFloat(this.ProdutosAvulsos.praValorPor);
                    }
                    this.ProdutosAvulsos.admGrupoTelas = { GrtId: this.ProdutosAvulsos.grtId }
                    this.ProdutosAvulsos.admCadastroTelas = { CdtId: this.ProdutosAvulsos.cdtId }
                    this.ProdutosAvulsos.cadIdiomas = { IdiId: this.ProdutosAvulsos.idiId }

             
                axios.put(`/Process/ProProdutosAvulsos/Update`, this.ProdutosAvulsos)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        CarregaImagens(){
            axios.get(`/Process/ProProdutosAvulsosImagens/GetAllByPraId/${this.ProdutosAvulsos.praId}`)
                    .then(response => {
                        this.ProdutosAvulsosImgs = response.data.sort((a, b) => {
                                                                                const valorA = parseInt(a.paiOrdem) || 0;
                                                                                const valorB = parseInt(b.paiOrdem) || 0;
                                                                                return valorA - valorB;
                                                                            });

                    });
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.praId;
                axios.delete(`/Process/ProProdutosAvulsos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item          
            this.ProdutosAvulsos = item;
            this.CarregaImagens();
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "praId", sortable: true, });
            this.headers.push({ text: "Nome Produto", value: "praNomeProduto", sortable: true, });
            this.headers.push({ text: "Referência", value: "praReferencia", sortable: true, });
            this.headers.push({ text: "Visível", value: "praVisivel", sortable: true, });
            this.headers.push({ text: "Ordem", value: "praOrdem", sortable: true, });
            this.headers.push({ text: "Valor De", value: "praValorDe", sortable: true, });
            this.headers.push({ text: "Valor Por", value: "praValorPor", sortable: true, });     
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.ProdutosAvulsos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/ProProdutosAvulsos/GetAll`) //GetAllByCtiId/16
                        .then(response => {
                            this.ListProdutosAvulsosGeral = response.data; 
                            if (this.ProdutosAvulsos.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.ProdutosAvulsos.cdtId = 16;
                    this.ProdutosAvulsos.grtId = 3;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
